import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {Spinner, Table} from 'reactstrap'

import TableHeader from 'components/TableComponents/TableHeader'
import TableBody from 'components/TableComponents/TableBody'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter'
import {ALLOWED_SORT_DIRECTIONS} from 'constants/tableConstants'
import {NOT_DEFINED} from 'constants/tableConstants'
import isAllowedSort from 'helpers/isAllowedSort'
import getNewSortDirection from 'helpers/getNewSortDirection'

const ReportsTable = ({
  postId,
  getListOptionsState,
  setListOptionsState,
  paginationLimit,
  getService,
  tableHeaderStructure,
  tableDataStructure,
  noDataText,
  hideLoader = false,
}) => {
  const {offset, sorting} = getListOptionsState()

  const [currentOffset, setCurrentOffset] = useState(offset)
  const [tableSort, setTableSort] = useState(sorting)

  const {data, pagination, loading} = getService(postId, {
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * paginationLimit)
    }
  }

  const headerStructure = useMemo(() => {
    let tableWithSort = [...tableHeaderStructure]
    const sortedItemIdx = tableWithSort?.findIndex((item) => item.sort === tableSort?.OrderKey)
    if (sortedItemIdx !== -1) {
      tableWithSort = tableWithSort.map((item) => ({...item, ...(item.sortDirection && {sortDirection: NOT_DEFINED})}))
      tableWithSort[sortedItemIdx].sortDirection = tableSort.OrderDirection
    }

    return tableWithSort
  }, [tableSort])

  const onClickHeader = useCallback(
    (column) => {
      if (column.sortOption) {
        const newDirection = getNewSortDirection(column?.sortDirection)

        setTableSort({
          OrderKey: column?.sort,
          OrderDirection: newDirection,
        })
        setCurrentOffset(0)
      }
    },
    [setTableSort, setCurrentOffset],
  )

  useEffect(() => {
    if (pagination?.totalCount === 0) return

    if (currentOffset >= pagination?.totalCount) {
      handleChangePage({selected: Math.ceil(pagination?.totalCount / paginationLimit) - 1})
    }
  }, [pagination, currentOffset, paginationLimit])

  useEffect(() => {
    setListOptionsState({offset: currentOffset, sorting: tableSort})
  }, [currentOffset, tableSort])

  return (
    <>
      <section className="d-flex flex-column justify-content-between">
        <div className="mt-2 pb-2">
          {loading && (
            <div className="patients-table d-flex justify-content-center align-items-center">
              {!hideLoader && <Spinner className="mx-auto" color="info" />}
            </div>
          )}
          {!loading && (
            <>
              {data?.length > 0 && (
                <div className="overflow-auto">
                  <Table className="table mb-0 ">
                    <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                    <TableBody data={data} structure={tableDataStructure} />
                  </Table>
                </div>
              )}

              {data?.length === 0 && <TableNoDataPlaceholder text={noDataText} />}
            </>
          )}
        </div>
      </section>
      {!loading && !!data.length && (
        <section className="mt-4">
          <TablePaginationFooter
            classnames={'d-flex'}
            handleChangePage={handleChangePage}
            total={pagination?.totalCount}
            currentOffset={pagination.currentOffset}
            limit={paginationLimit}
          />
        </section>
      )}
    </>
  )
}

export default ReportsTable
