export const REPORT_TABS = {
  REPORTED_USERS: 'reportedUsers',
  REPORTED_POSTS: 'reportedPosts',
  REPORTED_COMMENTS: 'reportedComments',
}

export const REPORTED_USERS_LIMIT = 12
export const REPORTED_POSTS_LIMIT = 12
export const REPORTED_COMMENTS_LIMIT = 12
export const USER_REPORTS_LIMIT = 12
export const POST_REPORTS_LIMIT = 15

export const REPORTED_STATUS_BADGE_COLORS = {
  New: { color: 'danger', value: 'New reports' },
  Old: { color: 'info', value: 'Viewed' },
}

export const USER_REPORTS_STATUS_BADGE_COLORS = {
  New: { color: 'danger', value: 'New' },
  Old: { color: 'info', value: 'Viewed' },
}

export const REPORTED_MANAGEMENT_FILTERS = [
  {
    label: 'by status',
    name: 'Filter',
    initialActive: 'All',
    options: [
      { name: 'All', value: 'All' },
      { name: 'Viewed', value: 'Viewed' },
      { name: 'New', value: 'New' },
    ],
  },
]
