import moment from 'moment'

const secondsBetweenDates = (dateEnd, dateStart) => {
  const start = dateStart ? moment(dateStart) : moment()
  const end = moment(dateEnd)

  return moment.duration(end.diff(start)).seconds()
}

export default secondsBetweenDates
