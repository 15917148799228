import {useMemo} from 'react'

const PAGINATION_GAP_DOTS = '...'

export const usePaginationPages = (totalPages, activePage) => {
  if (activePage > totalPages || activePage <= 0 || totalPages <= 0) {
    return []
  }

  return useMemo(
    () =>
      Array(totalPages)
        .fill(1)
        .map((n, i) => n + i)
        .reduce((acc, num) => {
          if (num === 1 || num === totalPages) {
            // * First and last pages
            return [...new Set([...acc, num])]
          }
          if ([activePage - 1, activePage, activePage + 1].includes(num)) {
            // * Pages near activePage
            return [...new Set([...acc, num])]
          }
          if (activePage === 1 && num <= 3) {
            // * First three pages if activePage === 1
            return [...new Set([...acc, num])]
          }
          if (activePage === totalPages && num >= totalPages - 2) {
            // * Last three pages if activePage is the last one
            return [...new Set([...acc, num])]
          }
          return acc
        }, [])
        .reduce((acc, num, index, array) => {
          if (array[index + 1] - num > 1) {
            return [...acc, num, PAGINATION_GAP_DOTS]
          }
          return [...acc, num]
        }, [])
        .map((value) => ({
          disabled: typeof value !== 'number',
          value,
        })),
    [totalPages, activePage],
  )
}
