import {useState, useEffect} from 'react'

import {USERS_LIMIT} from 'constants/userManagement'
import getApiError from 'helpers/getApiError'

import UserService from '../UserService'

export const useGetUsers = ({Status, Subscription, Search, OrderKey, OrderDirection, Offset, Limit = USERS_LIMIT}) => {
  const [usersList, setUsersList] = useState([])
  const [pagination, setPagination] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    UserService.getUsers({
      ...(Search && {Search}),
      ...(Subscription && {Subscription}),
      ...(Status && {Status}),
      ...(OrderKey && {'Order.Key': OrderKey}),
      ...(OrderDirection && {'Order.Direction': OrderDirection}),
      ...(Offset && {Offset}),
      Limit,
    })
      .then(({data, pagination}) => {
        setUsersList(data)
        setPagination((prev) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(Offset) ? Offset : prev.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [Status, Subscription, Search, OrderKey, OrderDirection, Offset, Limit])

  return {usersList, pagination, error, loading}
}
