import React from 'react'

const TableNoDataPlaceholder = ({text = 'No Data exists', classnames}) => {
  return (
    <div className={`d-flex align-items-center justify-content-center ${classnames || ''}`}>
      <label className="font-size-16 mt-4 text-secondary ">{text}</label>
    </div>
  )
}

export default TableNoDataPlaceholder
