export const requiredError = 'This field is required'
export const emailRequired = 'Email is required'
export const passwordRequired = 'Password is required'
export const invalidEmailFormat = 'Email is invalid'
export const passwordLength = 'Password must be from 8 to 50 symbols'
export const passwordContains = 'Should contain at least 1 letter, 1 digit, and 1 capital letter'
export const passwordMatch = 'Does not match the password'
export const confirmPasswordRequired = 'Confirm password is required'
export const noOnlySpacesValidation = 'Spaces only are not allowed'
export const invalidPasswordError = 'Password is invalid'
