import React from 'react'
import {useLocation} from 'react-router-dom'
import TableRow from './TableRow'
import TableCell from './TableCell'

const TableBody = ({data, structure, clickRowAction, rowClassName, ...rest}) => {
  const location = useLocation()

  return (
    <tbody>
      {data.map((dataRow, index) => {
        return (
          <TableRow key={index} className={rowClassName} onClick={() => clickRowAction(dataRow)}>
            {structure.map((cell, index) => (
              <TableCell key={cell?.name || index} dataRow={dataRow} location={location} {...cell} {...rest} />
            ))}
          </TableRow>
        )
      })}
    </tbody>
  )
}

export default TableBody
