import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Spinner} from 'reactstrap'

import ImageSquare from 'components/Common/ImageSquare'
import StatusBadge from 'components/Common/StatusBadge'
import {USER_MANAGEMENT_DATE_FORMAT} from 'constants/dateFormats'
import formatPhoneNumber from 'helpers/formatPhoneNumber'
import dateByFormat from 'helpers/dateByFormat'
import insertSpaces from 'helpers/insertSpaces'

const ProfileDetails = ({
                          loading,
                          user,
                          showTitle = true,
                          hideLoader = false,
                        }) => {
  if (loading) {
    return <div className="d-flex justify-content-center">{!hideLoader && <Spinner color="info" />}</div>
  }

  const infoBlocks = [
    {title: 'Status', content: <StatusBadge status={user?.status} />},
    {title: 'Name', content: user?.name ?? '-'},
    {title: 'User ID', content: user?.id},
    {title: 'Join date', content: user?.joinDate ? dateByFormat(user?.joinDate, USER_MANAGEMENT_DATE_FORMAT) : '-'},
    {title: 'Phone', content: user?.phoneNumber ? formatPhoneNumber(user?.phoneNumber) : '-'},
    {title: 'Subscription', content: user?.subscription ? insertSpaces(user?.subscription) : '-'},
    {title: 'Bio', content: user?.bio ?? '-'},
  ]

  return (
    <div>
      {showTitle && <h4 className="font-size-15">Profile details</h4>}
      <div className={`profileDetails ${!showTitle && 'pt-0'}`}>
        <div className="profileDetails-image">
          <ImageSquare src={user?.avatar?.originalPath ?? ''} />
        </div>
        <div className="profileDetails-info">
          {infoBlocks.map(({title, content}) => (
            <div key={title} className="profileDetails-info-line">
              <p className="profileDetails-info-title">{title}</p>
              <p className="profileDetails-info-content">{content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ProfileDetails)

ProfileDetails.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    avatar: PropTypes.shape({}),
    bio: PropTypes.string,
    id: PropTypes.number,
    joinDate: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.oneOf(['All', 'Active', 'Blocked']),
    subscription: PropTypes.oneOf(['All', 'Purchased', 'NotPurchased']),
  }),
  hideLoader: PropTypes.bool,
}
