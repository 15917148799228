import {FormFeedback} from 'reactstrap'
import React from 'react'

const FormError = (error) => {
  return (
    <FormFeedback className="position-absolute" type="invalid">
      {error}
    </FormFeedback>
  )
}

export default FormError
