import React from 'react'
import PropTypes from 'prop-types'
import {Nav, NavItem, NavLink} from 'reactstrap'

const Tabs = ({tabs, activeTab}) => {
  return (
    <Nav className="nav-tabs-custom">
      {tabs.map((tab) => (
        <NavItem key={tab.id}>
          <NavLink className={activeTab === tab.id ? 'active' : ''} onClick={tab.onClick}>
            {tab.title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

Tabs.propTypes = {
  activeTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
}

export default Tabs
