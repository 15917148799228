import React from 'react'
import PropTypes from 'prop-types'
import ImageSquare from 'components/Common/ImageSquare'

import sound from 'assets/images/sound-post.svg'
import text from 'assets/images/text-post.svg'
import videoPlay from 'assets/images/videoPlay-post.svg'
import {POST_TYPE} from 'constants/post'

const PostPreview = ({type, src}) => {
  switch (type) {
    case POST_TYPE.Sound:
      return (
        <div className="profilePosts-image">
          <ImageSquare src={sound} alt="" />
        </div>
      )
    case POST_TYPE.Text:
      return (
        <div className="profilePosts-image">
          <ImageSquare src={text} alt="" />
        </div>
      )

    case POST_TYPE.Video:
      return (
        <div className="profilePosts-image">
          <ImageSquare src={src ?? ''} alt="" />
          <img src={videoPlay} alt="" className="profilePosts-videoPlay" />
        </div>
      )

    default:
      return (
        <div className="profilePosts-image">
          <ImageSquare src={src ?? ''} alt="" />
        </div>
      )
  }
}

PostPreview.propTypes = {
  type: PropTypes.oneOf([POST_TYPE.Image, POST_TYPE.Sound, POST_TYPE.Text, POST_TYPE.Video]),
  src: PropTypes.string,
}

export default PostPreview
