import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'

import Breadcrumbs from 'components/Common/Breadcrumb'
import Tabs from 'components/Common/Tabs'
import TabContent from 'components/Common/TabContent'

const TABS = {
  INFO: 'info',
  POSTS: 'posts',
}

const UserDetailPageView = ({t, errorMsg, profileDetails, profilePosts}) => {
  const [activeTab, setActiveTab] = useState(TABS.INFO)

  const tabs = useMemo(
    () => [
      {
        id: TABS.INFO,
        title: t("User's info"),
        content: profileDetails,
        onClick: () => setActiveTab(TABS.INFO),
      },
      {
        id: TABS.POSTS,
        title: t('User’s posts'),
        content: profilePosts,
        onClick: () => setActiveTab(TABS.POSTS),
      },
    ],
    [profileDetails, profilePosts],
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t('User’s management')} breadcrumbItem={t("User's details")} />
        {errorMsg && (
          <div className="d-flex justify-content-center">
            <h4 className="error">{errorMsg}</h4>
          </div>
        )}

        {!errorMsg && (
          <>
            <div className="mb-3">
              <Tabs tabs={tabs} activeTab={activeTab} />
            </div>
            <TabContent tabs={tabs} activeTab={activeTab} />
          </>
        )}
      </Container>
    </div>
  )
}

export default withTranslation()(UserDetailPageView)

UserDetailPageView.propTypes = {
  errorMsg: PropTypes.string,
  profileDetails: PropTypes.node,
  profilePosts: PropTypes.node,
}
