import React from 'react'
import PropTypes from 'prop-types'
import {TabContent, TabPane} from 'reactstrap'

const TabContent_ = ({tabs, activeTab}) => {
  return (
    <TabContent activeTab={activeTab}>
      {tabs.map((tab) => (
        <TabPane key={tab.id} tabId={tab.id}>
          {tab.content}
        </TabPane>
      ))}
    </TabContent>
  )
}

TabContent_.propTypes = {
  activeTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
}

export default TabContent_
