import React from 'react';
import {Button} from 'reactstrap';

import dateByFormat from 'helpers/dateByFormat';
import {USER_MANAGEMENT_DATE_FORMAT} from 'constants/dateFormats';
import ReportBadge from 'components/ReportBadge';
import {getSubscriptionStatusBadgeColor} from 'helpers/getStatusBadgeColor';
import formatDuration from 'helpers/formatDuration';
import {SUBSCRIPTION_STATUS} from 'constants/subscriptionManagement';
import DateRangePickerWithoutInput from 'components/Common/dateRangePickerWithoutInput';

const showSubscriptionActionButtons = (status) => status === SUBSCRIPTION_STATUS.Active || status === SUBSCRIPTION_STATUS.Pending;

export const SUBSCRIPTION_TABLE_HEADER = [
  {
    label: 'Subscription status',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  },
  {
    label: 'Subscription initiator',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  },
  {
    label: 'Start date',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  },
  {
    label: 'End date',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  },
  {
    label: 'Duration',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  },
  {
    label: 'Actions',
    sortOption: false,
    classname: 'font-size-13 subscriptions-table-header column disable-word-wrap'
  }
]

export const getSubscriptionTableDataStructure = (onCancelSubscriptionClick, onChangeFreeSubscription, changeFreeSubscriptionLoading) => [
  {
    name: 'status',
    classname: 'user-row border-top',
    keyProp: 'status',
    Component: ({value}) => <ReportBadge value={value} color={getSubscriptionStatusBadgeColor(value)} />,
  },
  {
    name: 'initiator',
    classname: 'font-size-13 user-row border-top',
    keyProp: 'initiator',
    Component: ({value}) => (value ? value : '-'),
  },
  {
    name: 'startDate',
    classname: 'font-size-13 user-row border-top date-table-cell',
    keyProp: 'startDate',
    Component: ({value}) => dateByFormat(value, USER_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'endDate',
    classname: 'font-size-13 user-row border-top date-table-cell',
    keyProp: 'endDate',
    Component: ({value}) => dateByFormat(value, USER_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'duration',
    classname: 'font-size-13 user-row border-top',
    keyProp: 'duration',
    Component: ({value}) => (value ? formatDuration(value) : '-'),
  },
  {
    name: 'isFree',
    classname: 'font-size-13 user-row border-top py-2',
    keyProp: 'isFree',
    Component: ({value, dataRow}) => (value && showSubscriptionActionButtons(dataRow.status) ?
      <div className="subscription-actions d-flex">
        <DateRangePickerWithoutInput openButtonTitle="Expiration date"
                                     openButtonClassName="expiration-date"
                                     onApplyChanges={onChangeFreeSubscription}
                                     start={dataRow.startDate}
                                     end={dataRow.endDate}
                                     endDateOnly={true}
                                     subscriptionId={dataRow.id}
                                     loading={changeFreeSubscriptionLoading}
        />
        <Button color="danger"
                outline
                className="subscription-actions__button-cancel"
                onClick={() => onCancelSubscriptionClick(dataRow)}>Cancel</Button>
      </div> : ''),
  }
]
