import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import logoSm from 'assets/images/ic-y-letter.svg'
import logo from 'assets/images/ic-sidebar-logo.svg'
import withRouter from 'components/Common/withRouter'

import SidebarContent from './SidebarContent'

const Sidebar = (props) => {
  return (
    <div className="vertical-menu transition-250-width">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logoSm} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" height="17" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoSm} alt="" height="17.5" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" height="18" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
      </div>
      <div className="sidebar-background"></div>
    </div>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)))
