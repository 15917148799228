import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'
import SubscriptionService from '../SubscriptionService';
import {SUBSCRIPTION_LIMIT} from 'constants/subscriptionManagement';


export const useGetSubscriptionsByUserId = ({userId, Offset, Limit = SUBSCRIPTION_LIMIT}) => {
  const [subscriptions, setSubscriptions] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [refreshIsRequired, setRefreshIsRequired] = useState(false)
  const [pagination, setPagination] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })

  const updateSubscriptionsList = useCallback(() => setRefreshIsRequired((prev) => !prev), [setRefreshIsRequired])

  useEffect(() => {
    if (!userId) return

    setLoading(true)

    SubscriptionService.getSubscriptionsByUserId(userId, Offset, Limit)
      .then(({data, pagination}) => {
        setSubscriptions(data)
        setPagination((prev) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(Offset) ? Offset : prev.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => {
        setLoading(false)
      })
  }, [userId, Offset, Limit, refreshIsRequired])

  return {subscriptions, error, loading, pagination, updateSubscriptionsList}
}
