import React, {useCallback, useEffect, useState} from 'react'
import {Card, Container, Spinner, Table} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import debounce from 'lodash.debounce'

import {useGetUsers} from 'api/hooks/useGetUsers'
import SEO from 'components/Common/SEO'
import TableSearch from 'components/TableComponents/TableSearch'
import TableFilters from 'components/TableComponents/TableFilters'
import TableHeader from 'components/TableComponents/TableHeader'
import TableBody from 'components/TableComponents/TableBody'
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import withRouter from 'components/Common/withRouter'
import {ALLOWED_SORT_DIRECTIONS} from 'constants/tableConstants'
import {USER_MANAGEMENT_FILTERS, USERS_LIMIT} from 'constants/userManagement'
import isAllowedSort from 'helpers/isAllowedSort'
import {getUsersListOptions, setUsersListOptions} from 'helpers/usersListOptionsState'
import {buildHeaderStructure, onClickHeaderCallback} from 'helpers/tableHelpers'

import {USER_TABLE_DATA_STRUCTURE, USERS_TABLE_HEADER} from './tableComponents/usersTableStructure'

const TIME_TO_DEBOUNCE = 600

const Dashboard = ({router}) => {
  const {offset, query, filters: curFilters, sorting} = getUsersListOptions()

  const [currentOffset, setCurrentOffset] = useState(offset)
  const [searchString, setSearchString] = useState(query)
  const [filters, setFilters] = useState(curFilters)
  const [tableSort, setTableSort] = useState(sorting)

  const {usersList, pagination, loading} = useGetUsers({
    ...(filters?.Status && {Status: filters.Status}),
    ...(filters?.Subscription && {Subscription: filters.Subscription}),
    ...(searchString && {Search: searchString}),
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const debounceSearch = useCallback(
    debounce((e) => {
      setSearchString(e?.target?.value)
      setCurrentOffset(0)
    }, TIME_TO_DEBOUNCE),
    [setSearchString, setCurrentOffset],
  )

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * USERS_LIMIT)
    }
  }

  const handleChangeFilters = useCallback((values) => {
    setFilters(values)
    setCurrentOffset(0)
  }, [])

  const onUserRowClick = (dataRow) => {
    router.navigate(`${router.location.pathname}/${dataRow.id}`)
  }

  const headerStructure = buildHeaderStructure(USERS_TABLE_HEADER, tableSort)
  const onClickHeader = onClickHeaderCallback(setCurrentOffset, setTableSort)

  useEffect(() => {
    setUsersListOptions({offset: currentOffset, query: searchString, filters, sorting: tableSort})
  }, [searchString, currentOffset, filters, tableSort])

  return (
    <>
      <SEO title="User Management" />
      <div className="page-content pb-0">
        <h3 className="mb-4 users-page-header">User Management</h3>
        <Container fluid>
          <Card className="p-4 pb-3 users-page-content">
            <section className="d-flex justify-content-between pb-3 flex-wrap">
              <TableSearch onChange={debounceSearch} initialValue={searchString} />
              <TableFilters
                structure={USER_MANAGEMENT_FILTERS}
                onApplyFilter={handleChangeFilters}
                initialFilters={filters}
              />
            </section>
            <section className="d-flex flex-column justify-content-between">
              <div className="mt-2">
                {loading && (
                  <div className="patients-table d-flex justify-content-center align-items-center users-table-loader">
                    <Spinner className="mx-auto" color="info" />
                  </div>
                )}
                {!loading && (
                  <>
                    {usersList?.length > 0 && (
                      <div className="users-table-wrap">
                        <Table className="table mb-0 ">
                          <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                          <TableBody
                            data={usersList}
                            structure={USER_TABLE_DATA_STRUCTURE}
                            clickRowAction={onUserRowClick}
                          />
                        </Table>
                      </div>
                    )}
                    {!usersList.length && (
                      <TableNoDataPlaceholder classnames="users-table-loader" text="No Users found" />
                    )}
                  </>
                )}
              </div>
            </section>
            <section className="mt-4">
              <TablePaginationFooter
                classnames={!loading && usersList.length ? 'd-flex' : 'd-none'}
                handleChangePage={handleChangePage}
                total={pagination?.totalCount}
                currentOffset={pagination.currentOffset}
                limit={USERS_LIMIT}
              />
            </section>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default withTranslation()(withRouter(Dashboard))
