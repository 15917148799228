export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS'
export const VALIDATE_TOKEN_ERROR = 'VALIDATE_TOKEN_ERROR'
