import React from 'react'
import {Badge} from 'reactstrap'

import {getStatusBadgeColor} from 'helpers/getStatusBadgeColor'

const StatusBadge = ({value}) => {
  const color = getStatusBadgeColor(value)

  return (
    <Badge color={color} className="font-size-10 font-color-white ml-2 text-center user-status-badge">
      {value}
    </Badge>
  )
}

export default StatusBadge
