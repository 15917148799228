import React from 'react'

import TableSortIcon from './TableSort'

const TableHeader = ({columns, clickCellAction}) => {
  return (
    <thead>
      <tr>
        {columns.map((column, index) => {
          return (
            <th key={index} className={`table-caption ${column?.classname}`}>
              <span onClick={() => clickCellAction?.(column)} className={column?.sortOption ? 'cursor-pointer' : ''}>
                {column.label}
                {column.sortOption && column?.sortDirection && <TableSortIcon currentSort={column.sortDirection} />}
              </span>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
