import Http from './axios-config'

class CommentService {
  constructor() {
    this.http = new Http()
  }

  async getCommentById(commentId) {
    return await this.http.get(`/admin-comments/${commentId}`)
  }
}

export default new CommentService()
