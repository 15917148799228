import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import withRouter from 'components/Common/withRouter'
import {logoutUser} from 'store/actions'

const Logout = () => {
  const history = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser(history))
  }, [dispatch, history])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
