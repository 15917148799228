import {useEffect, useState} from 'react'

import {USER_REPORTS_LIMIT} from 'constants/reportManagement'
import getApiError from 'helpers/getApiError'

import ReportsService from '../ReportsService'

const useGetUserReportsList = ({userId, OrderKey, OrderDirection, Offset, Limit = USER_REPORTS_LIMIT}) => {
  const [userReports, setUserReports] = useState([])
  const [pagination, setPagination] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!userId) return

    setLoading(true)
    ReportsService.getReportedUserById(userId, {
      ...(OrderKey && {'Order.Key': OrderKey}),
      ...(OrderDirection && {'Order.Direction': OrderDirection}),
      ...(Offset && {Offset}),
      Limit,
    })
      .then(({data, pagination}) => {
        setUserReports(data)
        setPagination((prev) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(Offset) ? Offset : prev.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => {
        setLoading(false)
      })
  }, [userId, OrderKey, OrderDirection, Offset, Limit])

  return {userReports, error, loading, pagination}
}

export default useGetUserReportsList
