import Http from './axios-config'

class ReportsService {
  constructor() {
    this.http = new Http()
  }

  async getReportedUserById(id, params) {
    return await this.http.get(`/admin-reports/users/${id}`, {params})
  }

  async getReportedUsers(params) {
    return await this.http.get(`/admin-reports/users`, {params})
  }

  async getReportsByPostId(id, params) {
    return await this.http.get(`/admin-reports/posts/${id}`, {params})
  }

  async getReportsByCommentId(id, params) {
    return await this.http.get(`/admin-reports/comments/${id}`, {params})
  }

  async deleteReportedCommentById(id) {
    return await this.http.delete(`/admin-reports/comments/${id}`)
  }

  async getReportedPosts(params) {
    return await this.http.get(`/admin-reports/posts`, {params})
  }

  async getReportedComments(params) {
    return await this.http.get(`/admin-reports/comments`, {params})
  }
}

export default new ReportsService()
