import React, {useCallback, useMemo, useState} from 'react'
import {withTranslation} from 'react-i18next'

import {useGetUserById} from 'api/hooks/useGetUserById'
import {useBlockUnblockUser} from 'api/hooks/useBlockUnblockUser'
import {useDeleteUserById} from 'api/hooks/useDeleteUserById'
import toastr from 'components/toastr'
import withRouter from 'components/Common/withRouter'
import ConfirmModal from 'components/ConfirmModal'
import {BLOCK, UNBLOCK} from 'constants/userManagement'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import ProfileDetails from 'pages/UserDetailPage/ProfileDetails'

const UserDetails = ({t, router, hideSpinner = false}) => {
  const {userId} = router.params

  const [userBlockModal, setUserBlockModal] = useState(false)
  const [userDeleteModal, setUserDeleteModal] = useState(false)

  const {user, loading, update: updateUser} = useGetUserById(userId)
  const {deleteFetch, loading: deleteLoading} = useDeleteUserById()
  const {blockUnblockUser, loading: blockUserLoading} = useBlockUnblockUser(userId)

  const blockText = useMemo(() => (user?.status === 'Blocked' ? UNBLOCK : BLOCK), [user])

  const onBlockUser = useCallback(() => {
    blockUnblockUser(userId).then(() => {
      setUserBlockModal(false)
      updateUser()
      toastr.success(t(`User successfully ${blockText}ed`))
    })
  }, [blockText, setUserBlockModal, updateUser])

  const onDeleteUser = useCallback(() => {
    deleteFetch(userId).then(() => {
      setUserDeleteModal(false)
      router.navigate('/report-management')
      toastr.success(t(`User successfully deleted`))
    })
  }, [])

  const onBlockUserRequest = useCallback(() => setUserBlockModal(true), [setUserBlockModal])
  const onBlockUserCancel = useCallback(() => setUserBlockModal(false), [setUserBlockModal])
  const onDeleteUserRequest = useCallback(() => setUserDeleteModal(true), [setUserBlockModal])
  const onDeleteUserCancel = useCallback(() => setUserDeleteModal(false), [setUserBlockModal])

  return (
    <>
      <ProfileDetails
        showTitle={false}
        loading={loading}
        user={user}
        onBlockClick={onBlockUserRequest}
        onDeleteClick={onDeleteUserRequest}
        blockButtonText={capitalizeFirstLetter(blockText)}
        hideLoader={hideSpinner}
      />
      <ConfirmModal
        title={t(`${capitalizeFirstLetter(blockText)} user`)}
        isOpen={userBlockModal}
        onClickSubmit={onBlockUser}
        onClickCancel={onBlockUserCancel}
        submitButtonName={t(capitalizeFirstLetter(blockText))}
        submitButtonClassNames="btn btn-primary"
        isLoading={blockUserLoading}
      >
        {t(`Are you sure you want to ${blockText} the User?`)}
      </ConfirmModal>
      <ConfirmModal
        title={t(`Delete user`)}
        isOpen={userDeleteModal}
        onClickSubmit={onDeleteUser}
        onClickCancel={onDeleteUserCancel}
        submitButtonName={t(`Delete`)}
        submitButtonClassNames="btn btn-danger"
        isLoading={deleteLoading}
      >
        {t(`Are you sure you want to delete the User?`)}
      </ConfirmModal>
    </>
  )
}

export default withTranslation()(withRouter(UserDetails))
