import React from 'react'

import ReportBadge from 'components/ReportBadge'
import {NOT_DEFINED} from 'constants/tableConstants'
import {REPORT_MANAGEMENT_DATE_FORMAT} from 'constants/dateFormats'
import dateByFormat from 'helpers/dateByFormat'
import {getUserReportBadgeColor} from 'helpers/getStatusBadgeColor'

export const USER_REPORTS_TABLE_HEADER = [
  {
    label: 'Report message',
    sortOption: false,
    classname: 'font-size-13 text-start table-header name disable-word-wrap',
  },
  {
    label: 'Reporter name',
    sortOption: false,
    classname: 'font-size-13 text-start table-header number disable-word-wrap',
  },
  {
    label: 'Date / time',
    sortOption: true,
    classname: 'font-size-13 table-header text-start join-date disable-word-wrap',
    sort: 'CreatedAt',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Report status',
    sortOption: false,
    classname: 'font-size-13 text-start table-header status disable-word-wrap',
  },
]

export const USER_REPORTS_DATA_STRUCTURE = [
  {
    name: 'message',
    classname: 'font-size-13 user-row min-width-250 text-start border-top text-wrap',
    keyProp: 'message',
    Component: ({value}) => (
      <div lang="en" className="postReportsPage_td_message">
        {value ? value : '-'}
      </div>
    ),
  },
  {
    name: 'reporterName',
    classname: 'font-size-13 user-row text-start border-top',
    keyProp: 'reporterName',
    Component: ({value}) => (
      <div lang="en" className="postReportsPage_td_message">
        {value ? value : '-'}
      </div>
    ),
  },
  {
    name: 'createdAt',
    classname: 'font-size-13 user-row text-start border-top date-table-cell',
    keyProp: 'createdAt',
    Component: ({value}) => dateByFormat(value, REPORT_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'viewed',
    classname: 'user-row text-start border-top',
    keyProp: 'viewed',
    Component: ({value}) => <ReportBadge {...getUserReportBadgeColor(value ? 'Old' : 'New')} />,
  },
]
