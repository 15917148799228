// Status badge colors
export const USER_STATUS_BADGE_COLORS = {
  Active: 'success',
  Blocked: 'warning',
}

// Users table pagination
export const USERS_LIMIT = 12

export const USER_MANAGEMENT_FILTERS = [
  {
    label: 'by status',
    name: 'Status',
    initialActive: 'All',
    options: [
      {name: 'All', value: 'All'},
      {name: 'Active', value: 'Active'},
      {name: 'Blocked', value: 'Blocked'},
    ],
  },
  {
    label: 'by subscription',
    name: 'Subscription',
    initialActive: 'All',
    options: [
      {name: 'All', value: 'All'},
      {name: 'Purchased', value: 'Purchased'},
      {name: 'Not purchased', value: 'NotPurchased'},
    ],
  },
]

export const BLOCK = 'block'
export const UNBLOCK = 'unblock'
