import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import PostService from '../PostService'

const POSTS_PER_PAGE = 10

export const useGetPostsByUserId = (userId, {page = 1}) => {
  const [posts, setPosts] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const fetchPosts = useCallback(async () => {
    setLoading(true)
    return PostService.getPostsByUserId(userId, (page - 1) * POSTS_PER_PAGE, POSTS_PER_PAGE)
      .then(({data, pagination}) => {
        setPosts(data)
        setTotalPages(Math.ceil(pagination?.totalCount / POSTS_PER_PAGE))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [userId, page])

  useEffect(() => {
    if (!userId) return

    fetchPosts()
  }, [userId, page])

  return {posts, totalPages, error, loading, fetchPosts}
}
