import StorageService from 'services/StorageService'
import {
  REPORTED_USERS_PAGE_SETTINGS,
  REPORTED_POSTS_PAGE_SETTINGS,
  REPORTED_COMMENTS_PAGE_SETTINGS,
  POST_INFO_REPORTS_PAGE_SETTINGS,
  USER_REPORTS_PAGE_SETTINGS,
  COMMENT_INFO_REPORTS_PAGE_SETTINGS,
  USERS_PAGE_SETTINGS,
  PAGE_TAB,
  SUBSCRIPTIONS_PAGE_SETTINGS,
} from 'constants/storageConstants'

const storageService = new StorageService()

export const getPageListOptions = (storeKey) => () => {
  const { offset = 0, query = '', filters = {}, sorting = {} } = storageService.getValueByKey(storeKey) || {}

  return { offset, query, filters, sorting }
}

export const setPageListOptions =
  (storeKey) =>
    ({ offset, query, filters, sorting }) => {
      storageService.deleteValueByKey(storeKey)
      storageService.addValueByKey(storeKey, {
        offset: offset ?? 0,
        query: query ?? '',
        filters: filters ?? {},
        sorting: sorting ?? {},
      })
    }

export const getUsersListOptions = getPageListOptions(USERS_PAGE_SETTINGS)
export const setUsersListOptions = setPageListOptions(USERS_PAGE_SETTINGS)

export const getReportedUsersListOptions = getPageListOptions(REPORTED_USERS_PAGE_SETTINGS)
export const setReportedUsersListOptions = setPageListOptions(REPORTED_USERS_PAGE_SETTINGS)

export const getReportedPostsListOptions = getPageListOptions(REPORTED_POSTS_PAGE_SETTINGS)
export const setReportedPostsListOptions = setPageListOptions(REPORTED_POSTS_PAGE_SETTINGS)

export const getPostReportedsListOptions = getPageListOptions(POST_INFO_REPORTS_PAGE_SETTINGS)
export const setPostReportedsListOptions = setPageListOptions(POST_INFO_REPORTS_PAGE_SETTINGS)

export const getReportedCommentsListOptions = getPageListOptions(REPORTED_COMMENTS_PAGE_SETTINGS)
export const setReportedCommentsListOptions = setPageListOptions(REPORTED_COMMENTS_PAGE_SETTINGS)

export const getCommentReportedsListOptions = getPageListOptions(COMMENT_INFO_REPORTS_PAGE_SETTINGS)
export const setCommentsReportedsListOptions = setPageListOptions(COMMENT_INFO_REPORTS_PAGE_SETTINGS)

export const getSubscriptionPageUsersListOptions = getPageListOptions(SUBSCRIPTIONS_PAGE_SETTINGS)
export const setSubscriptionPageUsersListOptions = setPageListOptions(SUBSCRIPTIONS_PAGE_SETTINGS)

export const getReportsListOptions = (storeKey) => (entityId) => {
  const { id, options } = storageService.getValueByKey(storeKey) || {}
  if (entityId === id) {
    const { offset, sorting } = options || {}
    return { offset, sorting }
  } else {
    return { offset: 0, sorting: {} }
  }
}

export const setReportsListOptions = (storeKey) => (entityId, offset, sorting) => {
  storageService.deleteValueByKey(storeKey)
  storageService.addValueByKey(storeKey, {
    id: entityId,
    options: {
      offset,
      sorting,
    },
  })
}

export const getUserReportsListOptions = getReportsListOptions(USER_REPORTS_PAGE_SETTINGS)
export const setUserReportsListOptions = setReportsListOptions(USER_REPORTS_PAGE_SETTINGS)

export const getPageTab = (storeKey) => () => {
  const pageTab = storageService.getValueByKey(storeKey) || {}
  const { route = '', tab = '' } = pageTab || {}

  return { route, tab }
}

export const setPageTab = (storeKey) => (route, tab) => {
  storageService.deleteValueByKey(storeKey)
  storageService.addValueByKey(storeKey, {
    route,
    tab,
  })
}

export const getTab = getPageTab(PAGE_TAB)
export const setTab = setPageTab(PAGE_TAB)
