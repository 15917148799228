import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
} from './actionTypes'

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  resetSuccessMsg: null,
  resetError: null,
  isTokenValid: null,
  validateLoading: false,
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = {...state, forgetError: action.payload}
      break
    case RESET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      }
      break
    case RESET_PASSWORD_ERROR:
      state = {...state, forgetError: action.payload}
      break
    case VALIDATE_TOKEN:
      state = {...state, validateLoading: true, isTokenValid: false}
      break
    case VALIDATE_TOKEN_SUCCESS:
      state = {...state, validateLoading: false, isTokenValid: true}
      break
    case VALIDATE_TOKEN_ERROR:
      state = {...state, validateLoading: false, isTokenValid: false}
      break
    default:
      state = {...state}
      break
  }
  return state
}

export default forgetPassword
