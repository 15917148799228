import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import CommentService from '../CommentService'

export const useGetCommentById = (commentId) => {
  const [comment, setComment] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState(false)

  const update = useCallback(() => setIsNeedUpdate((prev) => !prev), [setIsNeedUpdate])

  useEffect(() => {
    if (!commentId) return

    setLoading(true)
    CommentService.getCommentById(commentId)
      .then(({data}) => setComment(data))
      .catch((error) => setError(getApiError(error)))
      .finally(() => {
        setLoading(false)
      })
  }, [commentId, isNeedUpdate])

  return {comment, error, loading, update}
}
