import {useState, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import UserService from '../UserService'

export const useBlockUnblockUser = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const blockUnblockUser = useCallback(async (userId) => {
    setLoading(true)
    return UserService.blockUnblockUser(userId)
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [])

  return {blockUnblockUser, error, loading}
}
