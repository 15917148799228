export const ASC = 'ASC'
export const DESC = 'DESC'
export const NOT_DEFINED = 'NOT_DEFINED'

export const ALLOWED_SORT_DIRECTIONS = [ASC, DESC]

export const TABLE_SORT_DIRECTIONS = [
  {
    direction: ASC,
    nextIndex: 1,
  },
  {
    direction: DESC,
    nextIndex: 2,
  },
  {
    direction: NOT_DEFINED,
    nextIndex: 0,
  },
]
