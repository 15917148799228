import {useCallback, useMemo} from 'react'
import {NOT_DEFINED} from 'constants/tableConstants'

import getNewSortDirection from './getNewSortDirection'

export const onClickHeaderCallback = (setCurrentOffset, setTableSort) =>
  useCallback(
    (column) => {
      if (column.sortOption) {
        const newDirection = getNewSortDirection(column?.sortDirection)

        setTableSort({
          OrderKey: column?.sort,
          OrderDirection: newDirection,
        })
        setCurrentOffset(0)
      }
    },
    [setTableSort, setCurrentOffset],
  )

export const buildHeaderStructure = (tableHeaderStructure, tableSort) =>
  useMemo(() => {
    let tableWithSort = [...tableHeaderStructure]
    const sortedItemIdx = tableWithSort?.findIndex((item) => item.sort === tableSort?.OrderKey)
    if (sortedItemIdx !== -1) {
      tableWithSort = tableWithSort.map((item) => ({...item, ...(item.sortDirection && {sortDirection: NOT_DEFINED})}))
      tableWithSort[sortedItemIdx].sortDirection = tableSort.OrderDirection
    }

    return tableWithSort
  }, [tableSort])
