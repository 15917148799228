import React from 'react'

export const FAIL_TITLE = 'Expired!'
export const SUCCESS_TITLE = 'Success!'

export const SUCCESS_SUBTITLE = (email) => (
  <>
    Recovery link was successfully sent to <br /> {email}
  </>
)
export const ERROR_SUBTITLE = (
  <>
    You can resend a link only 5 times <br /> per 24h. Try again tomorrow.
  </>
)
export const EXPIRED_SUBTITLE = 'Your recovery link has expired. You can resend it to get a new one!'

export const EXPIRED_BUTTON_NAME = 'Resend recovery link'
export const ERROR_BUTTON_NAME = 'Ok'

export const SEND_TIMEOUT = 59
export const INTERVAL = 1

export const STORAGE_COUNTDOWN_KEY = 'YTT:pwSendCountdown'
