import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Container, Spinner, Table} from 'reactstrap'
import _ from 'lodash';


import {SUBSCRIPTION_LIMIT} from 'constants/subscriptionManagement';
import TableHeader from 'components/TableComponents/TableHeader';
import TableBody from 'components/TableComponents/TableBody';
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder';
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter';
import DateRangePickerWithoutInput from 'components/Common/dateRangePickerWithoutInput';

import {
  getSubscriptionTableDataStructure,
  SUBSCRIPTION_TABLE_HEADER
} from './subscriptionsTableStructure';


const ProfileSubscriptions = ({
                                loading,
                                subscriptions,
                                pagination,
                                handleChangePage,
                                onCancelSubscriptionClick,
                                onGiveFreeSubscription,
                                giveFreeSubscriptionLoading,
                                onChangeFreeSubscription,
                                changeFreeSubscriptionLoading
                              }) => {


  return (
    <Container fluid>
        <section className='mb-3 d-flex justify-content-between align-items-center'>
          <div className='font-size-15 fw-bold'>Subscription status changes</div>
          <DateRangePickerWithoutInput openButtonTitle="Free subscription"
                                       openButtonClassName="free-subscription"
                                       onApplyChanges={onGiveFreeSubscription}
                                       loading={giveFreeSubscriptionLoading}
          />
        </section>
        <section className="d-flex flex-column justify-content-between">
          <div className="mt-2">
            {loading && (
              <div className="d-flex justify-content-center align-items-center users-table-loader">
                <Spinner className="mx-auto" color="info" />
              </div>
            )}
            {!loading && (
              <>
                {subscriptions?.length > 0 && (
                  <div className="users-table-wrap">
                    <Table className="table mb-0 ">
                      <TableHeader columns={SUBSCRIPTION_TABLE_HEADER} />
                      <TableBody
                        data={subscriptions}
                        clickRowAction={_.noop}
                        structure={getSubscriptionTableDataStructure(onCancelSubscriptionClick, onChangeFreeSubscription, changeFreeSubscriptionLoading)}
                      />
                    </Table>
                  </div>
                )}
                {!subscriptions.length && (
                  <TableNoDataPlaceholder classnames="users-table-loader" text="User don’t have a Subscription" />
                )}
              </>
            )}
          </div>
        </section>
        <section className="mt-4">
          <TablePaginationFooter
            classnames={!loading && subscriptions.length ? 'd-flex' : 'd-none'}
            handleChangePage={handleChangePage}
            total={pagination?.totalCount}
            currentOffset={pagination.currentOffset}
            limit={SUBSCRIPTION_LIMIT}
          />
        </section>
    </Container>
  )
}

ProfileSubscriptions.propTypes = {
  loading: PropTypes.bool,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      duration: PropTypes.string,
      status: PropTypes.string,
      isFree: PropTypes.bool,
      initiator: PropTypes.string,
      id: PropTypes.number
    }),
  ),
  pagination: PropTypes.object,
  handleChangePage: PropTypes.func,
  onCancelSubscriptionClick: PropTypes.func,
  onChangeFreeSubscription: PropTypes.func,
  onGiveFreeSubscription: PropTypes.func,
  giveFreeSubscriptionLoading: PropTypes.bool,
  changeFreeSubscriptionLoading: PropTypes.bool
}

export default withTranslation()(ProfileSubscriptions)

