import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {useFormikContext, ErrorMessage} from 'formik'
import {FormGroup, Label, Input, Button} from 'reactstrap'

import FormError from 'components/Common/FormError'
import {formatHiddenEmail} from 'helpers/formatHiddenEmail'

const EmailForm = ({t, email, loading}) => {
  const {dirty, handleBlur, handleChange, values, touched, errors} = useFormikContext()

  const isInvalid = (field) => touched?.[field] && !!errors?.[field]

  return (
    <>
      <Label>{t('Current email')}</Label>
      <p>{formatHiddenEmail(email)}</p>
      <FormGroup>
        <Label for="exampleEmail">{t('New Email')}</Label>
        <Input
          id="email"
          name="email"
          placeholder={t('Enter new email')}
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isInvalid('email')}
          disabled={loading}
        />
        <ErrorMessage name="email" render={FormError} />
      </FormGroup>
      <div className="settings-button-box">
        <Button type="submit" disabled={!dirty || loading} color="primary" className="settings-button">
          {t('Change')}
        </Button>
      </div>
    </>
  )
}

EmailForm.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string,
}

export default withTranslation()(EmailForm)
