import React from 'react'
import PropTypes from 'prop-types'

import NoPhoto from 'assets/images/ic-user.svg'

const ImageSquare = ({src, onError}) => {
  return (
    <div className="imageSquare-root">
      <div className="imageSquare-box">
        {src && <img className="imageSquare-image" src={src} alt="" />}
        {!src && (
          <div className="d-flex align-items-center justify-content-center imageSquare-image bg-light">
            <img src={NoPhoto} alt="" onError={onError} />
          </div>
        )}
      </div>
    </div>
  )
}

ImageSquare.propTypes = {
  src: PropTypes.string,
  onError: PropTypes.func,
}

export default ImageSquare
