import Http from './axios-config'

class AuthService {
  constructor() {
    this.http = new Http()
  }

  async login(values) {
    return await this.http.post('/admin-sessions', values)
  }

  async logout() {
    return await this.http.delete('/admin-sessions')
  }

  async sendRestorePassword(values) {
    return await this.http.post(`/admin-verifications/password`, values)
  }

  async changePassword(values) {
    return await this.http.put(`/admin-verifications/password`, values)
  }

  async changePasswordInSettings(values) {
    return await this.http.post(`/admin-settings/password`, values)
  }

  async confirmChangePasswordInSettings(values) {
    return await this.http.put(`/admin-settings/password`, values)
  }

  async changeEmailInSetting(values) {
    return await this.http.post(`/admin-settings/email`, values)
  }

  async confirmChangeEmailInSetting(values) {
    return await this.http.put(`/admin-settings/email`, values)
  }

  async validateToken(values) {
    return await this.http.post(`/admin-verifications/token`, values)
  }
}

export default new AuthService()
