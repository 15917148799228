import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
} from './actionTypes'

export const userForgetPassword = (userValues, toastr, history, disableRedirect) => {
  return {
    type: FORGET_PASSWORD,
    payload: {userValues, toastr, history, disableRedirect},
  }
}

export const userForgetPasswordSuccess = (message) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = (message) => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const userResetPassword = (userValues, toastr, history) => {
  return {
    type: RESET_PASSWORD,
    payload: {userValues, toastr, history},
  }
}

export const userResetPasswordSuccess = (message) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userResetPasswordError = (message) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  }
}

export const userValidateToken = (token, email, history) => {
  return {
    type: VALIDATE_TOKEN,
    payload: {token, email, history},
  }
}

export const userValidateTokenSuccess = () => {
  return {
    type: VALIDATE_TOKEN_SUCCESS,
  }
}

export const userValidateTokenError = () => {
  return {
    type: VALIDATE_TOKEN_ERROR,
  }
}
