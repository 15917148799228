import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Spinner} from 'reactstrap'

import withRouter from 'components/Common/withRouter'
import SEO from 'components/Common/SEO'
import queryParser from 'helpers/queryStringParser'
import {userValidateToken} from 'store/auth/forgetpwd/actions'

const ResetPassword = ({router}) => {
  const {token = '', email = ''} = queryParser(router?.location?.search)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userValidateToken(token, email, router.navigate))
  }, [])

  return (
    <>
      <SEO title="Loading..." />
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <Spinner className="text-primary" />
      </div>
    </>
  )
}

export default withRouter(ResetPassword)
