export const USER_MANAGEMENT_DATE_FORMAT = 'DD MMM YYYY'
export const REPORT_MANAGEMENT_DATE_FORMAT = 'DD MMM YYYY / hh:mm A'
export const FREE_SUBSCRIPTION_LIMIT_IN_DAYS = 365
export const NUMBER_OF_HOURS_IN_DAY = 24
export const NUMBER_OF_MINUTES_IN_HOUR = 60
export const NUMBER_OF_SECONDS_IN_MINUTE = 60
export const NUMBER_OF_MILLISECONDS_IN_SECOND = 1000

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
