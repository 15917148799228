import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import UserService from '../UserService'

export const useGetUserById = (userId) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState(false)

  const update = useCallback(() => setIsNeedUpdate((prev) => !prev), [setIsNeedUpdate])

  useEffect(() => {
    if (!userId) return

    setLoading(true)
    UserService.getUserById(userId)
      .then(({data}) => setUser(data))
      .catch((error) => setError(getApiError(error)))
      .finally(() => {
        setLoading(false)
      })
  }, [userId, isNeedUpdate])

  return {user, error, loading, update}
}
