import React from 'react'
import {Col, Row} from 'reactstrap'

const AuthHeader = ({headerText}) => {
  return (
    <div className="auth-header">
      <Row>
        <Col xs={12}>
          <div className="text-light p-4 pb-5">
            <h5 className="mb-0 line-height-24">{headerText}</h5>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AuthHeader
