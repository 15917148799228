import Axios from 'axios'

import {SESSION} from 'constants/storageConstants'
import StorageService from 'services/StorageService'

import Http from './axios-config'

const storageService = new StorageService()

export const sendRefreshToken = async (refreshToken) => {
  const response = await Axios.put(`${Http.api.common}/${Http.versions.v1}/admin-sessions`, refreshToken)
  storageService.addValueByKey(SESSION, response.data.data)
  return response
}
