import {useCallback, useState} from 'react';

import getApiError from 'helpers/getApiError';
import SubscriptionService from '../SubscriptionService';

export const useDeleteSubscriptionById = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const deleteSubscription = useCallback(async (subscriptionId, callback) => {
    setLoading(true)
    return SubscriptionService.deleteSubscriptionById(subscriptionId)
      .then(() => callback())
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [])

  return {deleteSubscription, error, loading}
}
