import React from 'react'

import {icons} from 'constants/svgIcons'

const Icon = ({icon, isActive}) => {
  const Icon = icons[icon]

  return <Icon fill={isActive ? 'white' : 'none'} />
}

export default Icon
