import React from 'react'
import {Card, Col} from 'reactstrap'
import successMail from 'assets/images/ic-mail-success.svg'
import errorMail from 'assets/images/ic-mail-error.svg'

const LinkCard = ({title, subtitle, success, children}) => {
  return (
    <Col md={8} lg={6} xl={5}>
      <Card className="overflow-hidden restore-password-message">
        <div className="auth-logo">
          <div className="logo-light d-block">
            <div className="d-flex flex-column align-items-center ">
              <span className="avatar-title rounded-circle bg-transparent mb-3 mt-2">
                <img src={success ? successMail : errorMail} alt="" className="rounded-circle" height="72" />
              </span>
              <h5 className="mt-1 mb-2 text-center">{title}</h5>
              <div className="mb-3 restore-password-message-subtitle text-center text-muted">{subtitle}</div>
              {children}
            </div>
          </div>
        </div>
      </Card>
    </Col>
  )
}

export default LinkCard
