import { useState, useEffect } from 'react'

import { USERS_LIMIT } from 'constants/userManagement'
import getApiError from 'helpers/getApiError'

import ReportsService from '../ReportsService'

export const useGetReportedPosts = ({ Filter, Search, OrderKey, OrderDirection, Offset, Limit = USERS_LIMIT }) => {
  const [reportedPosts, setReportedPosts] = useState([])
  const [pagination, setPagination] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    ReportsService.getReportedPosts({
      ...(Search && { Search }),
      ...(Filter && { Filter }),
      ...(OrderKey && { 'Order.Key': OrderKey }),
      ...(OrderDirection && { 'Order.Direction': OrderDirection }),
      ...(Offset && { Offset }),
      Limit,
    })
      .then(({ data, pagination }) => {
        setReportedPosts(data)
        setPagination((prev) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(Offset) ? Offset : prev.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [Filter, Search, OrderKey, OrderDirection, Offset, Limit])

  return { data: reportedPosts, pagination, error, loading }
}
