import React from 'react'
import {useDispatch} from 'react-redux'
import {ErrorMessage, Formik} from 'formik'
import {Card, CardBody, Col, Container, Form, Label, Row} from 'reactstrap'

import SEO from 'components/Common/SEO'
import FormError from 'components/Common/FormError'
import PasswordInput from 'components/Common/PasswordInput'
import withRouter from 'components/Common/withRouter'
import toastr from 'components/toastr/index'
import {setNewPwValidationSchema} from 'constants/validationSchemes'
import queryParser from 'helpers/queryStringParser'
import isAllFieldsFilled from 'helpers/isAllFieldsFilled'
import trimField from 'helpers/trimField'
import {userResetPassword} from 'store/auth/forgetpwd/actions'

import AuthHeader from './components/AuthHeader'
import AuthLogo from './components/AuthLogo'

const SetNewPassword = ({router}) => {
  const {token = ''} = queryParser(router?.location?.search)
  const dispatch = useDispatch()

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const handleSubmit = (values) => {
    const newValues = trimField(trimField(values, 'password'), 'confirmPassword')
    dispatch(
      userResetPassword(
        {
          ...newValues,
          token,
        },
        toastr,
        router.navigate,
      ),
    )
  }

  return (
    <>
      <SEO title="Set new password" />
      <div className="account-pages auth-wrapper">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden border-radius-8">
                <AuthHeader headerText="Set new password" />
                <CardBody className="pt-0 ">
                  <AuthLogo />
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={setNewPwValidationSchema}
                      enableReinitialize
                    >
                      {({handleSubmit, handleBlur, handleChange, touched, errors, values}) => {
                        const isInvalid = (field) => touched?.[field] && !!errors?.[field]

                        return (
                          <Form className="form-horizontal" onSubmit={handleSubmit}>
                            <div className="mb-3 pb-2">
                              <Label className={`form-label ${isInvalid('password') && 'text-danger'}`}>
                                Create new password
                              </Label>
                              <PasswordInput
                                name="password"
                                value={values?.password}
                                placeholder="Enter new password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={isInvalid('password')}
                                error={<ErrorMessage name="password" render={FormError} />}
                              />
                            </div>

                            <div className="mb-3 pb-2">
                              <Label className={`form-label ${isInvalid('confirmPassword') && 'text-danger'}`}>
                                Confirm new password
                              </Label>
                              <PasswordInput
                                name="confirmPassword"
                                value={values?.confirmPassword}
                                placeholder="Confirm new password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={isInvalid('confirmPassword')}
                                error={<ErrorMessage name="confirmPassword" render={FormError} />}
                              />
                            </div>

                            <Row className="pt-1">
                              <Col xs={12} className="text-center w-100">
                                <button
                                  className="btn btn-primary w-100"
                                  type="submit"
                                  disabled={isAllFieldsFilled(values)}
                                >
                                  Create
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(SetNewPassword)
