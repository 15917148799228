import React, {useCallback, useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import debounce from 'lodash.debounce'
import {Spinner, Table} from 'reactstrap'

import withRouter from 'components/Common/withRouter'
import TableSearch from 'components/TableComponents/TableSearch'
import TableFilters from 'components/TableComponents/TableFilters'
import TableHeader from 'components/TableComponents/TableHeader'
import TableBody from 'components/TableComponents/TableBody'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import TablePaginationFooter from 'components/TableComponents/TablePaginationFooter'
import {ALLOWED_SORT_DIRECTIONS} from 'constants/tableConstants'
import {REPORTED_MANAGEMENT_FILTERS} from 'constants/reportManagement'
import isAllowedSort from 'helpers/isAllowedSort'
import {buildHeaderStructure, onClickHeaderCallback} from 'helpers/tableHelpers'

const TIME_TO_DEBOUNCE = 600

const ReportsManagementTab = ({
  getListOptionsState,
  setListOptionsState,
  paginationLimit,
  getService,
  onClickRow,
  tableHeaderStructure,
  tableDataStructure,
  noDataText,
  noDataTextWithFilters,
  tabName,
}) => {
  const {offset, query, filters: curFilters, sorting} = getListOptionsState()

  const [currentOffset, setCurrentOffset] = useState(offset)
  const [searchString, setSearchString] = useState(query)
  const [filters, setFilters] = useState(curFilters)
  const [tableSort, setTableSort] = useState(sorting)

  const {data, pagination, loading} = getService({
    ...(filters?.Filter && {Filter: filters.Filter}),
    ...(searchString && {Search: searchString}),
    ...(tableSort?.OrderKey && isAllowedSort(tableSort?.OrderDirection, ALLOWED_SORT_DIRECTIONS) && tableSort),
    Offset: currentOffset,
  })

  const debounceSearch = useCallback(
    debounce((e) => {
      setSearchString(e?.target?.value)
      setCurrentOffset(0)
    }, TIME_TO_DEBOUNCE),
    [setSearchString, setCurrentOffset],
  )

  const handleChangePage = ({selected}) => {
    if (!isNaN(selected)) {
      setCurrentOffset(selected * paginationLimit)
    }
  }

  const handleChangeFilters = useCallback((values) => {
    setFilters(values)
    setCurrentOffset(0)
  }, [])

  const headerStructure = buildHeaderStructure(tableHeaderStructure, tableSort)
  const onClickHeader = onClickHeaderCallback(setCurrentOffset, setTableSort)

  useEffect(() => {
    setListOptionsState({offset: currentOffset, query: searchString, filters, sorting: tableSort})
  }, [searchString, currentOffset, filters, tableSort])

  return (
    <>
      <section className="d-flex justify-content-between pb-3 flex-wrap">
        <TableSearch onChange={debounceSearch} initialValue={searchString} />
        <TableFilters
          structure={REPORTED_MANAGEMENT_FILTERS}
          onApplyFilter={handleChangeFilters}
          initialFilters={filters}
          tabName={tabName}
        />
      </section>
      <section className="d-flex flex-column justify-content-between">
        <div className="mt-2">
          {loading && (
            <div className="patients-table d-flex justify-content-center align-items-center users-table-loader">
              <Spinner className="mx-auto" color="info" />
            </div>
          )}
          {!loading && (
            <>
              {data?.length > 0 && (
                <div className="users-table-wrap">
                  <Table className="table mb-0 ">
                    <TableHeader columns={headerStructure} clickCellAction={onClickHeader} />
                    <TableBody data={data} structure={tableDataStructure} clickRowAction={onClickRow} />
                  </Table>
                </div>
              )}

              {data.length === 0 && (
                <>
                  {filters.Filter !== REPORTED_MANAGEMENT_FILTERS.initialActive || searchString.length !== 0 ? (
                    <TableNoDataPlaceholder classnames="users-table-loader" text={noDataTextWithFilters} />
                  ) : (
                    <TableNoDataPlaceholder classnames="users-table-loader" text={noDataText} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      <section className="mt-4">
        <TablePaginationFooter
          classnames={!loading && data.length ? 'd-flex' : 'd-none'}
          handleChangePage={handleChangePage}
          total={pagination?.totalCount}
          currentOffset={pagination.currentOffset}
          limit={paginationLimit}
        />
      </section>
    </>
  )
}

export default withTranslation()(withRouter(ReportsManagementTab))
