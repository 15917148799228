import React from 'react'

import AscSortIcon from 'assets/images/ic-sort-arrow-up.svg'
import DescSortIcon from 'assets/images/ic-sort-arrow-down.svg'
import SortIcon from 'assets/images/ic-sort-arrow.svg'
import {ASC, DESC, NOT_DEFINED} from 'constants/tableConstants'

const TableSortIcon = ({currentSort}) => {
  return (
    <>
      {currentSort === ASC && <img src={AscSortIcon} className="ms-2" alt="" />}
      {currentSort === DESC && <img src={DescSortIcon} className="ms-2" alt="" />}
      {currentSort === NOT_DEFINED && <img src={SortIcon} className="ms-2" alt="" />}
    </>
  )
}

export default TableSortIcon
