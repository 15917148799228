export default class StorageService {
  getValueByKey(key) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null
  }

  deleteValueByKey(key) {
    localStorage.removeItem(key)
  }

  addValueByKey(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  clear() {
    localStorage.clear()
  }
}
