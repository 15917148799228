import * as yup from 'yup'

import {
  confirmPasswordRequired,
  emailRequired,
  invalidEmailFormat,
  invalidPasswordError,
  noOnlySpacesValidation,
  passwordContains,
  passwordLength,
  passwordMatch,
  passwordRequired,
} from './errorMessages'
import {EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from './lengthValidity'
import {passwordValidation, passwordValidationOnlyLatin, strWithNoOnlySpaces} from './validityPatterns'

export const loginValidationSchema = yup.object().shape({
  email: yup.string().email(invalidEmailFormat).max(EMAIL_MAX_LENGTH, invalidEmailFormat).required(emailRequired),
  password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH, passwordLength)
    .max(PASSWORD_MAX_LENGTH, passwordLength)
    .trim(invalidPasswordError)
    .matches(strWithNoOnlySpaces, noOnlySpacesValidation)
    .matches(passwordValidationOnlyLatin, invalidPasswordError)
    .matches(passwordValidation, passwordContains)
    .required(passwordRequired),
})

export const forgotPwValidationSchema = yup.object().shape({
  email: yup.string().email(invalidEmailFormat).max(EMAIL_MAX_LENGTH, invalidEmailFormat).required(emailRequired),
})

export const setNewPwValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required(passwordRequired)
    .min(PASSWORD_MIN_LENGTH, passwordLength)
    .max(PASSWORD_MAX_LENGTH, passwordLength)
    .trim(invalidPasswordError)
    .matches(strWithNoOnlySpaces, noOnlySpacesValidation)
    .matches(passwordValidationOnlyLatin, invalidPasswordError)
    .matches(passwordValidation, passwordContains),
  confirmPassword: yup
    .string()
    .required(confirmPasswordRequired)
    .trim(invalidPasswordError)
    .test('password-confirmation', passwordMatch, (value, context) => value === context.parent.password),
})

export const emailFormValidationSchema = yup.object().shape({
  email: yup.string().email(invalidEmailFormat).max(EMAIL_MAX_LENGTH, invalidEmailFormat).required(emailRequired),
})

export const passwordFormValidationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required(passwordRequired)
    .min(PASSWORD_MIN_LENGTH, passwordLength)
    .max(PASSWORD_MAX_LENGTH, passwordLength)
    .trim(invalidPasswordError)
    .matches(strWithNoOnlySpaces, noOnlySpacesValidation)
    .matches(passwordValidationOnlyLatin, invalidPasswordError)
    .matches(passwordValidation, passwordContains),
  password: yup
    .string()
    .required(passwordRequired)
    .min(PASSWORD_MIN_LENGTH, passwordLength)
    .max(PASSWORD_MAX_LENGTH, passwordLength)
    .trim(invalidPasswordError)
    .matches(strWithNoOnlySpaces, noOnlySpacesValidation)
    .matches(passwordValidationOnlyLatin, invalidPasswordError)
    .matches(passwordValidation, passwordContains),
  confirmPassword: yup
    .string()
    .required(confirmPasswordRequired)
    .trim(invalidPasswordError)
    .test('password-confirmation', passwordMatch, (value, context) => value === context.parent.password),
})
