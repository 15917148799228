import {useState, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import ReportsService from '../ReportsService'

export const useDeleteCommentById = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const deleteFetch = useCallback(async (commentId) => {
    setLoading(true)
    return ReportsService.deleteReportedCommentById(commentId)
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [])

  return {deleteFetch, error, loading}
}
