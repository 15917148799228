import React from 'react'
import * as _ from 'lodash'

const DefaultComponent = ({value: children}) => children

const TableCell = ({dataRow, location, classname, keyProp, Component = DefaultComponent, ...rest}) => {
  const value = _.get(dataRow, keyProp, dataRow)

  return (
    <td className={' ' + classname}>
      <Component className="table-text" value={value} location={location} dataRow={dataRow} {...rest} />
    </td>
  )
}

export default TableCell
