import React from 'react'

const TableRow = ({className, onClick, children}) => {
  return (
    <tr className={'cursor-pointer ' + className} onClick={() => onClick && onClick()}>
      {children}
    </tr>
  )
}

export default TableRow
