import {useState, useCallback} from 'react'

import AuthService from '../AuthService'

export const useChangePassword = () => {
  const [loading, setLoading] = useState(false)

  const changePasswordFetch = useCallback(async (values) => {
    setLoading(true)
    return AuthService.changePasswordInSettings(values).finally(() => setLoading(false))
  }, [])

  return {changePasswordFetch, loading}
}
