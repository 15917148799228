import {useState, useEffect} from 'react'

const useMediaQuery = (queryInput = '', options = {}) => {
  const query = queryInput.replace(/^@media( ?)/m, '')

  const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const {defaultMatches = false, matchMedia = supportMatchMedia ? window.matchMedia : null} = {
    ...options,
  }

  const [match, setMatch] = useState(() => matchMedia(query).matches || defaultMatches)

  useEffect(() => {
    let active = true

    if (!supportMatchMedia) {
      return undefined
    }

    const queryList = matchMedia(query)
    const updateMatch = () => {
      if (active) {
        setMatch(queryList.matches)
      }
    }
    updateMatch()

    queryList.addEventListener('change', updateMatch)
    return () => {
      active = false
      queryList.removeEventListener('change', updateMatch)
    }
  }, [query, matchMedia, supportMatchMedia])

  return match
}

export default useMediaQuery
