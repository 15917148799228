import React from 'react'
import {Navigate} from 'react-router-dom'

// Authentication related pages
import Login from 'pages/Authentication/Login'
import Logout from 'pages/Authentication/Logout'
import ForgetPwd from 'pages/Authentication/ForgetPassword'

// Dashboard
import Dashboard from 'pages/Dashboard/index'
import UserDetailPage from 'pages/UserDetailPage'
import ResetPassword from 'pages/Authentication/ResetPassword'
import RecoverLink from 'pages/Authentication/RecoverLink'
import SetNewPassword from 'pages/Authentication/SetNewPassword'
import Reports from 'pages/Reports'
import PostReports from 'pages/PostReports'
import CommentReports from 'pages/CommentReports'
import ReportedUserDetails from 'pages/ReportedUserDetails'
import SubscriptionManagement from 'pages/SubscriptionManagement'
import UserSubscriptionsPage from 'pages/UserSubscriptionsPage'

// Settings
import Settings from 'pages/Settings'
import ChangePassword from 'pages/Settings/ChangePassword'
import ChangeEmail from 'pages/Settings/ChangeEmail'

const authProtectedRoutes = [
  {path: '/user-management', component: <Dashboard />},
  {path: '/user-management/:userId', component: <UserDetailPage />},

  {path: '/report-management', component: <Reports />},
  {path: '/report-management/user/:userId', component: <ReportedUserDetails />},
  {path: '/report-management/post/:postId', component: <PostReports />},
  {path: '/report-management/comment/:commentId', component: <CommentReports />},

  {path: '/subscription-management', component: <SubscriptionManagement />},
  {path: '/subscription-management/:userId', component: <UserSubscriptionsPage />},

  {path: '/settings', component: <Settings />},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '*',
    exact: true,
    component: <Navigate to="/user-management" />,
  },
]

const publicRoutes = [
  {path: '/logout', component: <Logout />},
  {path: '/login', component: <Login />},
  {path: '/forgot-password', component: <ForgetPwd />},
  {path: '/reset-password', component: <ResetPassword />},
  {path: '/set-new-password', component: <SetNewPassword />},
  {path: '/expired-link', component: <RecoverLink />},
  {path: '/change-password', component: <ChangePassword />},
  {path: '/change-email', component: <ChangeEmail />},
]

export {authProtectedRoutes, publicRoutes}
export {default as Authmiddleware} from './route'
