import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Spinner, Button} from 'reactstrap'

import defaultUser from 'assets/images/default-user.svg'
import sound from 'assets/images/sound-post.svg'
import videoPlay from 'assets/images/videoPlay-post.svg'
import ImageSquare from 'components/Common/ImageSquare'
import {POST_TYPE} from 'constants/post'
import dateByFormat from 'helpers/dateByFormat'

const PostInfo = ({t, post, loading, onPostDownload, onPostDelete, hideLoader = false}) => {
  const getImageSrc = useCallback(
    (postType) => {
      switch (postType) {
        case POST_TYPE.Video: // Video 1214
          return post?.video?.compactPath
        case POST_TYPE.Image: // Image 1358
          return post?.image?.compactPath
        case POST_TYPE.Sound: // Sound 1354
          return sound
        default: // Text 1161
          return defaultUser
      }
    },
    [post],
  )

  const onImgLoadError = useCallback(({currentTarget}) => {
    currentTarget.onerror = null
    currentTarget.src = defaultUser
  }, [])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center users-table-loader">
        <div className="postReportsPage_image_box d-flex justify-content-center align-items-center">
          {!hideLoader && <Spinner className="mx-auto" color="info" />}
        </div>
      </div>
    )
  }

  return (
    <div className="postReportsPage_info_box">
      {post?.type !== POST_TYPE.Text && (
        <div className="postReportsPage_image_box">
          <ImageSquare src={getImageSrc(post?.type)} onError={onImgLoadError} />
          {post?.type === POST_TYPE.Video && (
            <img src={videoPlay} alt="" className="postReportsPage_image_video_play" />
          )}
        </div>
      )}
      <div className="postReportsPage_info">
        <div className="postReportsPage_info_title">{t('Post creator')}</div>
        <div className="postReportsPage_creator">
          <div className="postReportsPage_avatar_box">
            <img
              src={post?.creator?.avatar?.compactPath ?? defaultUser}
              onError={onImgLoadError}
              className="postReportsPage_avatar"
            />
          </div>
          <div className="postReportsPage_info_text">{post?.creator?.name}</div>
        </div>
        <div className="postReportsPage_info_title">{t('Creation date and time')}</div>
        <div className="postReportsPage_info_text">{post?.createdAt ? dateByFormat(post?.createdAt) : ''}</div>
        <div className="postReportsPage_info_title">{t('Post title')}</div>
        <div className="postReportsPage_info_text">{post?.title}</div>
        {post?.type === POST_TYPE.Text && (
          <>
            <div className="postReportsPage_info_title">{t('Post description')}</div>
            <div className="postReportsPage_info_text">{post?.description}</div>
          </>
        )}
      </div>
      <div className="postReportsPage_info_actions">
        {post?.type !== POST_TYPE.Text && (
          <Button className="profilePosts-action-view" color="primary" onClick={onPostDownload}>
            {t('Download')}
          </Button>
        )}
        <Button color="danger" onClick={onPostDelete}>
          {t('Delete')}
        </Button>
      </div>
    </div>
  )
}

PostInfo.propTypes = {
  loading: PropTypes.bool,
  post: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.shape({}),
    video: PropTypes.shape({}),
    createdAt: PropTypes.string,
    album: PropTypes.string,
  }),
  onPostDownload: PropTypes.func,
  onPostDelete: PropTypes.func,
  hideLoader: PropTypes.bool,
}

export default withTranslation()(PostInfo)
