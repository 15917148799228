import React from 'react'

import ReportBadge from 'components/ReportBadge'
import { REPORT_MANAGEMENT_DATE_FORMAT } from 'constants/dateFormats'
import { NOT_DEFINED } from 'constants/tableConstants'
import dateByFormat from 'helpers/dateByFormat'
import { getUserReportBadgeColor } from 'helpers/getStatusBadgeColor'

export const REPORTED_USERS_TABLE_HEADER = [
  { label: 'Name', sortOption: false, classname: 'font-size-13 text-start table-header name disable-word-wrap' },
  {
    label: 'Total number of reports',
    sortOption: true,
    classname: 'font-size-13 text-start table-header number disable-word-wrap',
    sort: 'Total',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Date / time',
    sortOption: true,
    classname: 'font-size-13 table-header text-start join-date disable-word-wrap',
    sort: 'CreatedAt',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Report status',
    sortOption: false,
    classname: 'font-size-13 text-start table-header status disable-word-wrap',
  },
]

export const REPORTED_POSTS_TABLE_HEADER = [
  { label: 'Post title', sortOption: false, classname: 'font-size-13 text-start table-header name disable-word-wrap' },
  {
    label: 'Total number of reports',
    sortOption: true,
    classname: 'font-size-13 text-start table-header number disable-word-wrap',
    sort: 'Total',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Date / time',
    sortOption: true,
    classname: 'font-size-13 table-header text-start join-date disable-word-wrap',
    sort: 'CreatedAt',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Report status',
    sortOption: false,
    classname: 'font-size-13 text-start table-header status disable-word-wrap',
  },
]

export const REPORTED_COMMENTS_TABLE_HEADER = [
  {
    label: 'Post title',
    sortOption: false,
    classname: 'font-size-13 text-start table-header name disable-word-wrap'
  },
  {
    label: 'Reported comment or reply',
    sortOption: false,
    classname: 'font-size-13 text-start table-header name disable-word-wrap'
  },
  {
    label: 'Total number of reports',
    sortOption: true,
    classname: 'font-size-13 text-start table-header number disable-word-wrap',
    sort: 'Total',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Date / time',
    sortOption: true,
    classname: 'font-size-13 table-header text-start join-date disable-word-wrap',
    sort: 'CreatedAt',
    sortDirection: NOT_DEFINED,
  },
  {
    label: 'Report status',
    sortOption: false,
    classname: 'font-size-13 text-start table-header status disable-word-wrap',
  },
]

export const REPORTED_USERS_TABLE_DATA_STRUCTURE = [
  {
    name: 'name',
    classname: 'font-size-13 min-width-250 user-row text-start text-wrap border-top',
    keyProp: 'name',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'totalReports',
    classname: 'font-size-13 user-row text-start border-top ',
    keyProp: 'totalReports',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'createdAt',
    classname: 'font-size-13 user-row text-start border-top date-table-cell',
    keyProp: 'createdAt',
    Component: ({ value }) => dateByFormat(value, REPORT_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'viewed',
    classname: 'user-row text-start border-top',
    keyProp: 'viewed',
    Component: ({ value }) => <ReportBadge {...getUserReportBadgeColor(value ? 'Old' : 'New')} />,
  },
]

export const REPORTED_POSTS_TABLE_DATA_STRUCTURE = [
  {
    name: 'title',
    classname: 'font-size-13 min-width-250 user-row text-start text-wrap text-break border-top',
    keyProp: 'title',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'totalReports',
    classname: 'font-size-13 user-row text-start border-top ',
    keyProp: 'totalReports',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'createdAt',
    classname: 'font-size-13 user-row text-start border-top date-table-cell',
    keyProp: 'createdAt',
    Component: ({ value }) => dateByFormat(value, REPORT_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'viewed',
    classname: 'user-row text-start border-top',
    keyProp: 'viewed',
    Component: ({ value }) => <ReportBadge value={value ? 'Viewed' : 'New'} color={value ? 'info' : 'danger'} />,
  },
]

export const REPORTED_COMMENTS_TABLE_DATA_STRUCTURE = [
  {
    name: 'postTitle',
    classname: 'font-size-13 min-width-250 user-row text-start text-wrap text-break border-top',
    keyProp: 'postTitle',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'text',
    classname: 'font-size-13 min-width-250 user-row text-start text-wrap text-break border-top',
    keyProp: 'text',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'totalReports',
    classname: 'font-size-13 user-row text-start border-top ',
    keyProp: 'totalReports',
    Component: ({ value }) => (value ? value : '-'),
  },
  {
    name: 'createdAt',
    classname: 'font-size-13 user-row text-start border-top date-table-cell',
    keyProp: 'createdAt',
    Component: ({ value }) => dateByFormat(value, REPORT_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'viewed',
    classname: 'user-row text-start border-top',
    keyProp: 'viewed',
    Component: ({ value }) => <ReportBadge value={value ? 'Viewed' : 'New'} color={value ? 'info' : 'danger'} />,
  },
]
