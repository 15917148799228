export const USER = 'YTT::user'
export const SESSION = 'YTT::session'
export const USER_EMAIL = 'YTT::user_email'
export const USERS_PAGE_SETTINGS = 'YTT::usersPageSettings'
export const REPORTED_USERS_PAGE_SETTINGS = 'YTT::reportedUsersPageSettings'
export const REPORTED_POSTS_PAGE_SETTINGS = 'YTT::reportedPostsPageSettings'
export const REPORTED_COMMENTS_PAGE_SETTINGS = 'YTT::reportedCommentsPageSettings'
export const POST_INFO_REPORTS_PAGE_SETTINGS = 'YTT::postInfoReportsPageSettings'
export const USER_REPORTS_PAGE_SETTINGS = 'YTT::userReportsPageSettings'
export const COMMENT_INFO_REPORTS_PAGE_SETTINGS = 'YTT::commentInfoReportsPageSettings'
export const PAGE_TAB = 'YTT::pageTab'
export const SUBSCRIPTIONS_PAGE_SETTINGS = 'YTT::subscriptionsPageSettings'
