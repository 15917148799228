import React, {useState} from 'react'
import {Input} from 'reactstrap'
import _ from 'lodash'

import show from 'assets/images/ic-show.svg'
import hide from 'assets/images/ic-hide.svg'

const PasswordInput = ({
  name = 'password',
  value = '',
  placeholder = 'Enter password',
  onChange = _.noop,
  onBlur = _.noop,
  invalid = false,
  error,
  ...rest
}) => {
  const [isShow, setIsShow] = useState(false)

  return (
    <div className="password-input">
      <Input
        name={name}
        value={value}
        type={isShow ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        className="password-input-field "
        {...rest}
      />
      <img
        src={isShow ? hide : show}
        alt=""
        className="password-input-button "
        onClick={() => setIsShow((prev) => !prev)}
      />
      {invalid && error}
    </div>
  )
}

export default PasswordInput
