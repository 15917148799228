import { useState, useEffect } from 'react'

import { REPORTED_COMMENTS_LIMIT } from 'constants/reportManagement'
import getApiError from 'helpers/getApiError'

import ReportsService from '../ReportsService'

export const useGetReportedComments = ({ Filter, Search, OrderKey, OrderDirection, Offset, Limit = REPORTED_COMMENTS_LIMIT }) => {
  const [reportedComments, setReportedComments] = useState([])
  const [pagination, setPagination] = useState({
    nextOffset: 0,
    totalCount: 0,
    currentOffset: 0,
  })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    ReportsService.getReportedComments({
      ...(Search && { Search }),
      ...(Filter && { Filter }),
      ...(OrderKey && { 'Order.Key': OrderKey }),
      ...(OrderDirection && { 'Order.Direction': OrderDirection }),
      ...(Offset && { Offset }),
      Limit,
    })
      .then(({ data, pagination }) => {
        setReportedComments(data)
        setPagination((prev) => ({
          nextOffset: pagination?.nextOffset,
          totalCount: pagination?.totalCount,
          currentOffset: Number.isInteger(Offset) ? Offset : prev.nextOffset,
        }))
      })
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [Filter, Search, OrderKey, OrderDirection, Offset, Limit])

  return { data: reportedComments, pagination, error, loading }
}
