import {takeEvery, fork, all, call, put} from 'redux-saga/effects'

import AuthService from 'api/AuthService'
import getApiError from 'helpers/getApiError'

// Login Redux States
import {FORGET_PASSWORD, RESET_PASSWORD, VALIDATE_TOKEN} from './actionTypes'
import {
  userForgetPasswordError,
  userForgetPasswordSuccess,
  userResetPasswordError,
  userValidateTokenSuccess,
} from './actions'

//Include Both Helper File with needed methods

function* forgetUser({payload: {userValues, toastr, history, disableRedirect = false}}) {
  try {
    const {data} = yield call([AuthService, AuthService.sendRestorePassword], userValues)

    if (!disableRedirect) {
      toastr
        .success(
          data?.['Message'] ||
            `If the account with email ${userValues?.email} exists, then you will receive an email with instructions on how to reset your password.`,
        )
        .css('width', '800px')
      history('/login')
    }

    yield put(userForgetPasswordSuccess(data?.['Message']))
  } catch (error) {
    if (!disableRedirect) {
      toastr.error(getApiError(error))
    }
    yield put(userForgetPasswordError(getApiError(error)))
  }
}

function* resetPassword({payload: {userValues, toastr, history}}) {
  try {
    const data = yield call([AuthService, AuthService.changePassword], userValues)

    toastr
      .success(data?.['Message'] || `New password was successfully created. Now you can sign in to your account`)
      .css('width', '609px')

    history('/login')
  } catch (error) {
    yield put(userResetPasswordError(getApiError(error)))
  }
}

function* validateToken({payload: {token, email, history}}) {
  try {
    const {data = {}} = yield call([AuthService, AuthService.validateToken], {token})
    !data?.isValid
      ? history(`/expired-link?email=${email}`)
      : history(`/set-new-password?token=${token}&email=${email}`)

    yield put(userValidateTokenSuccess())
  } catch (error) {
    //
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
  yield takeEvery(VALIDATE_TOKEN, validateToken)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
