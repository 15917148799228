import {useState, useCallback} from 'react'

import AuthService from '../AuthService'

export const useChangeEmail = () => {
  const [loading, setLoading] = useState(false)

  const changeEmailFetch = useCallback(async (values) => {
    setLoading(true)
    return AuthService.changeEmailInSetting(values).finally(() => setLoading(false))
  }, [])

  return {changeEmailFetch, loading}
}
