import React, {useCallback, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Spinner, Button} from 'reactstrap'
import defaultUser from 'assets/images/default-user.svg'
import dateByFormat from 'helpers/dateByFormat'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import {BLOCK, UNBLOCK} from 'constants/userManagement'

const CommentInfo = ({t, comment, loading, onCommentDelete, onBlockUser, hideLoader = false}) => {
  const blockText = useMemo(() => (comment?.user?.status === 'Blocked' ? UNBLOCK : BLOCK), [comment])
  const blockButtonText = useCallback(capitalizeFirstLetter(blockText))

  const commentText = useMemo(() => (comment?.isReply ? comment?.commentText : comment?.text), [comment])
  const replyText = useMemo(() => (comment?.isReply ? comment?.text : null), [comment])

  const onImgLoadError = useCallback(({currentTarget}) => {
    currentTarget.onerror = null
    currentTarget.src = defaultUser
  }, [])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center users-table-loader">
        <div className="postReportsPage_image_box d-flex justify-content-center align-items-center">
          {!hideLoader && <Spinner className="mx-auto" color="info" />}
        </div>
      </div>
    )
  }

  return (
    <div className="postReportsPage_info_box">
      <div className="postReportsPage_info">
        <div className="postReportsPage_info_title">{t('Comment/reply creator')}</div>
        <div className="postReportsPage_creator">
          <div className="postReportsPage_avatar_box">
            <img
              src={comment?.user?.avatar?.compactPath ?? defaultUser}
              onError={onImgLoadError}
              className="postReportsPage_avatar"
            />
          </div>
          <div className="postReportsPage_info_text">{comment?.user?.name}</div>
        </div>
        <div className="postReportsPage_info_title">{t('Creation date and time')}</div>
        <div className="postReportsPage_info_text">{comment?.createdAt ? dateByFormat(comment?.createdAt) : ''}</div>
        <div className="postReportsPage_info_title">{t('Post title')}</div>
        <div className="postReportsPage_info_text">{comment?.postTitle}</div>
        <div className="postReportsPage_info_title">{t('Comment text')}</div>
        <div className="postReportsPage_info_text">
          {commentText}
          <div className="postReportsPage_edit_text">{!comment?.isReply && comment?.isEdited ? ' (Edited)' : ''}</div>
        </div>
        {comment?.isReply ? <div className="postReportsPage_info_title">{t('Reply text')}</div> : <></>}
        {comment?.isReply ? (
          <div className="postReportsPage_info_text">
            {replyText}
            <div className="postReportsPage_edit_text">{comment?.isEdited ? ' (Edited)' : ''}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="postReportsPage_info_actions">
        <Button color="primary" onClick={onBlockUser} outline={blockButtonText === 'Unblock'}>
          {t(blockButtonText)}
        </Button>
        <Button color="danger" onClick={onCommentDelete}>
          {t('Delete')}
        </Button>
      </div>
    </div>
  )
}

CommentInfo.propTypes = {
  loading: PropTypes.bool,
  comment: PropTypes.shape({
    id: PropTypes.number,
    postTitle: PropTypes.string,
    commentText: PropTypes.string,
    user: PropTypes.shape({}),
    text: PropTypes.string,
    createdAt: PropTypes.string,
    isEdited: PropTypes.bool,
    repliesCount: PropTypes.number,
    isMy: PropTypes.bool,
    isReply: PropTypes.bool,
  }),
  onCommentDelete: PropTypes.func,
  onBlockUser: PropTypes.func,
  hideLoader: PropTypes.bool,
}

export default withTranslation()(CommentInfo)
