import React from 'react'
import PropTypes from 'prop-types'
import {Pagination as PaginationLib, PaginationItem, PaginationLink} from 'reactstrap'

import {usePaginationPages} from 'hooks/usePaginationPages'

const Pagination = ({activePage, setActivePage, totalPages}) => {
  const pages = usePaginationPages(totalPages, activePage)

  return (
    <PaginationLib aria-label="Page navigation example">
      <PaginationItem disabled={activePage === 1}>
        <PaginationLink first onClick={setActivePage.bind(null, 1)} />
      </PaginationItem>

      {pages.map(({value, disabled}, index) => (
        <PaginationItem key={index} disabled={disabled} active={activePage === value}>
          <PaginationLink onClick={setActivePage.bind(null, value)}>{value}</PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem disabled={activePage === totalPages}>
        <PaginationLink last onClick={setActivePage.bind(null, totalPages)} />
      </PaginationItem>
    </PaginationLib>
  )
}

Pagination.propTypes = {
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
  totalPages: PropTypes.number,
}

export default Pagination
