import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {useFormikContext, ErrorMessage} from 'formik'
import {FormGroup, Label, Input, Button} from 'reactstrap'

import FormError from 'components/Common/FormError'

const PasswordForm = ({t, loading}) => {
  const {dirty, handleBlur, handleChange, values, touched, errors} = useFormikContext()

  const isInvalid = (field) => touched?.[field] && !!errors?.[field]

  return (
    <>
      <FormGroup>
        <Label for="password">{t('Current password')}</Label>
        <Input
          id="oldPassword"
          name="oldPassword"
          placeholder={t('Enter сurrent password')}
          type="password"
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isInvalid('oldPassword')}
          disabled={loading}
        />
        <ErrorMessage name="oldPassword" render={FormError} />
      </FormGroup>
      <FormGroup>
        <Label for="password">{t('New password')}</Label>
        <Input
          id="password"
          name="password"
          placeholder={t('Enter new password')}
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isInvalid('password')}
          disabled={loading}
        />
        <ErrorMessage name="password" render={FormError} />
      </FormGroup>
      <FormGroup>
        <Label for="confirmPassword">{t('Confirm new password')}</Label>
        <Input
          id="confirmPassword"
          name="confirmPassword"
          placeholder={t('Confirm new password')}
          type="password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isInvalid('confirmPassword')}
          disabled={loading}
        />
        <ErrorMessage name="confirmPassword" render={FormError} />
      </FormGroup>
      <div className="settings-button-box">
        <Button type="submit" disabled={!dirty || loading} color="primary" className="settings-button">
          {t('Change')}
        </Button>
      </div>
    </>
  )
}

PasswordForm.propTypes = {
  loading: PropTypes.bool,
}

export default withTranslation()(PasswordForm)
