import React from 'react'
import {Container, Row} from 'reactstrap'
import LinkCard from './Common/LinkCard'

const LinkStatusContainer = ({title, subtitle, children, success = false}) => {
  return (
    <div className="account-pages auth-wrapper">
      <Container>
        <Row className="justify-content-center ">
          <LinkCard title={title} subtitle={subtitle} success={success}>
            {children}
          </LinkCard>
        </Row>
      </Container>
    </div>
  )
}

export default LinkStatusContainer
