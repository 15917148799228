import React, {useId} from 'react'
import PropTypes from 'prop-types'

const RadioButton = ({text, onChange, checked, ...props}) => {
  const id = useId()
  return (
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input filters-radio"
        id={id}
        onChange={onChange}
        checked={checked}
        {...props}
      />
      <label className="form-check-label font-size-13 fw-normal" htmlFor={id}>
        {text}
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RadioButton
