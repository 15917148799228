import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap'
import _ from 'lodash'

import RadioButton from '../RadioButton'

const TableFilters = ({structure, onApplyFilter, initialFilters, tabName}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [activeFilters, setActiveFilters] = useState({})
  const [isApplied, setIsApplied] = useState(false)

  const getInitial = useCallback(() => {
    let initFilters = {}

    structure.forEach((filter) => {
      initFilters = {
        ...initFilters,
        ...(filter?.initialActive && {[filter?.name]: filter.initialActive}),
      }
    })

    return initFilters
  }, [structure])

  const isInitialFiltersExists = useMemo(() => Object.keys(initialFilters).length > 0, [initialFilters])
  const initialsFiltersIsDefault = useMemo(() => _.isEqual({...initialFilters}, getInitial()), [initialFilters])

  const isDefault = useCallback(() => {
    let isDefault = true

    structure.forEach((item) => {
      if (activeFilters?.[item.name] !== item.initialActive) {
        isDefault = false
      }
    })

    return isDefault
  }, [activeFilters, structure])

  const clearFilters = () => {
    const init = getInitial()
    setIsApplied(false)
    setAppliedFilters(init)
    setActiveFilters(init)
    onApplyFilter(init)
  }
  const isShowClear = useMemo(() => isApplied, [isApplied])

  const handleApply = useCallback(() => {
    setIsFilterOpen(false)
    onApplyFilter(activeFilters)
    setAppliedFilters(activeFilters)
    setIsApplied(!isDefault())
  }, [activeFilters])

  const onOptionClick = (value, groupName) => {
    setActiveFilters((prev) => ({...prev, [groupName]: value}))
  }

  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev)
    setActiveFilters(appliedFilters)
  }

  useEffect(() => {
    const initial = isInitialFiltersExists ? {...initialFilters} : getInitial()

    if (isInitialFiltersExists && !initialsFiltersIsDefault) {
      setIsApplied(isInitialFiltersExists)
    }

    setAppliedFilters(initial)
  }, [])

  return (
    <div className="d-flex flex-row justify-content-between align-self-end pb-2 pb-sm-0">
      <Dropdown isOpen={isFilterOpen} toggle={toggleFilter} className="filter-button">
        <DropdownToggle tag="button" className="btn users-table--filters--dropdown__toggle">
          <span>Filter</span>
          <i className={`mdi mdi-chevron-${isFilterOpen ? 'up' : 'down'} ps-1 ms-2`} />
        </DropdownToggle>
        {isShowClear && (
          <Button color="link" className="btn btn-link pe-0" onClick={clearFilters}>
            Clear
          </Button>
        )}
        <DropdownMenu className="dropdown-menu-left filters-dropdown">
          <section className="users-table--filters--dropdown">
            {structure.map((group, index) => (
              <section className={`users-table--filters--dropdown__next-section ${!index && 'mt-0'}`} key={index}>
                <span className="font-size-10 fw-medium line-height-15 text-uppercase text-secondary mb-2">
                  {group?.label}
                </span>

                {group?.options?.map(({name, value}, index) => (
                  <div key={`${group.name}-${name}-${index}`}>
                    <RadioButton
                      text={name}
                      checked={activeFilters?.[group.name] === value}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onOptionClick(value, group.name)
                        }
                      }}
                      value={value}
                      name={`${group.name + tabName}Filter`}
                    />
                  </div>
                ))}
              </section>
            ))}
            <Button outline color="primary" className="w-100 mt-3" onClick={handleApply}>
              Apply filter
            </Button>
          </section>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default TableFilters
