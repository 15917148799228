import moment from 'moment'

const formatDuration = (duration) => {
  const days = moment.duration(duration).asDays() > 0
    ? Math.floor(moment.duration(duration).asDays())
    : Math.ceil(moment.duration(duration).asDays())
  const hours = moment.duration(duration).hours()
  const minutes = moment.duration(duration).minutes()

  return `${days}d ${hours}h ${minutes}m`

}

export default formatDuration
