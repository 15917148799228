import Http from './axios-config'

class UserService {
  constructor() {
    this.http = new Http()
  }

  async getUserById(id) {
    return await this.http.get(`/admin-users/${id}`)
  }

  async getUsers(params) {
    return await this.http.get(`/admin-users`, {params})
  }

  async blockUnblockUser(id) {
    return await this.http.patch(`/admin-users/${id}`)
  }

  async deleteUserById(id) {
    return await this.http.delete(`/admin-users/${id}`)
  }
}

export default new UserService()
