import PropTypes from 'prop-types'
import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'

import {authProtectedRoutes, publicRoutes, Authmiddleware} from 'routes'

// layouts Format
import VerticalLayout from 'components/VerticalLayout/'
import NonAuthLayout from 'components/NonAuthLayout'

// Import scss
import 'assets/scss/theme.scss'

const App = () => {
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} exact={true} />
      ))}

      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <Authmiddleware>
              <VerticalLayout>{route.component}</VerticalLayout>
            </Authmiddleware>
          }
          key={idx}
          exact={true}
        />
      ))}

      <Route path="*" element={<Navigate to="/user-management" replace />} />
    </Routes>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
