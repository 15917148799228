import React from 'react'

import {NOT_DEFINED} from 'constants/tableConstants'
import {USER_MANAGEMENT_DATE_FORMAT} from 'constants/dateFormats'
import dateByFormat from 'helpers/dateByFormat'
import insertSpaces from 'helpers/insertSpaces'
import formatPhoneNumber from 'helpers/formatPhoneNumber'

import StatusBadge from './StatusBadge'

export const USERS_TABLE_HEADER = [
  {label: 'User ID', sortOption: false, classname: 'font-size-13 table-header id disable-word-wrap'},
  {label: 'Name', sortOption: false, classname: 'font-size-13 table-header name disable-word-wrap'},
  {label: 'Phone number', sortOption: false, classname: 'font-size-13 table-header number disable-word-wrap'},
  {
    label: 'Join date',
    sortOption: true,
    classname: 'font-size-13 table-header join-date disable-word-wrap',
    sort: 'joinDate',
    sortDirection: NOT_DEFINED,
  },
  {label: 'Subscription', sortOption: false, classname: 'font-size-13 table-header sub disable-word-wrap'},
  {label: 'Status', sortOption: false, classname: 'font-size-13 table-header status disable-word-wrap'},
]

export const USER_TABLE_DATA_STRUCTURE = [
  {name: 'id', classname: 'font-size-13 user-row border-top', keyProp: 'id'},
  {
    name: 'name',
    classname: 'font-size-13 user-row border-top',
    keyProp: 'name',
    Component: ({value}) => (value ? value : '-'),
  },
  {
    name: 'phoneNumber',
    classname: 'font-size-13 user-row border-top ',
    keyProp: 'phoneNumber',
    Component: ({value}) => (value ? formatPhoneNumber(value) : '-'),
  },
  {
    name: 'joinDate',
    classname: 'font-size-13 user-row border-top date-table-cell',
    keyProp: 'joinDate',
    Component: ({value}) => dateByFormat(value, USER_MANAGEMENT_DATE_FORMAT),
  },
  {
    name: 'subscription',
    classname: 'font-size-13 user-row border-top',
    keyProp: 'subscription',
    Component: ({value}) => (value ? insertSpaces(value) : '-'),
  },
  {
    name: 'status',
    classname: 'user-row border-top',
    keyProp: 'status',
    Component: ({value}) => <StatusBadge value={value} />,
  },
]
