export const SUBSCRIPTION_MANAGEMENT_USERS_LIMIT = 12
export const SUBSCRIPTION_LIMIT = 12

export const SUBSCRIPTION_MANAGEMENT_USERS_LIST_FILTERS = [
  {
    label: 'by status',
    name: 'Status',
    initialActive: 'All',
    options: [
      {name: 'All', value: 'All'},
      {name: 'Active', value: 'Active'},
      {name: 'Blocked', value: 'Blocked'},
    ],
  },
  {
    label: 'by subscription',
    name: 'Subscription',
    initialActive: 'All',
    options: [
      {name: 'All', value: 'All'},
      {name: 'Purchased', value: 'Purchased'},
      {name: 'Not purchased', value: 'NotPurchased'},
    ],
  },
]

export const SUBSCRIPTION_STATUS = {
  Active: 'Active',
  Pending: 'Pending',
  Stopped: 'Stopped',
  Passed: 'Passed'
}

export const SUBSCRIPTION_STATUS_BADGE_COLORS = {
  [SUBSCRIPTION_STATUS.Active]: 'success',
  [SUBSCRIPTION_STATUS.Pending]: 'info',
  [SUBSCRIPTION_STATUS.Stopped]: 'warning',
  [SUBSCRIPTION_STATUS.Passed]: 'danger'
}
