import {call, put, takeEvery} from 'redux-saga/effects'

import AuthService from 'api/AuthService'
import StorageService from 'services/StorageService'
import {SESSION, USER, USER_EMAIL} from 'constants/storageConstants'

import {LOGIN_USER, LOGOUT_USER} from './actionTypes'
import {apiError, loginSuccess, logoutUserSuccess} from './actions'

const storageService = new StorageService()

function* loginUser({payload: {userValues, history}}) {
  try {
    const newUserValues = {...userValues}
    const {
      data: {user, token},
    } = yield call([AuthService, AuthService.login], newUserValues)
    yield put(loginSuccess({user, token}))
    storageService.addValueByKey(USER, user)
    storageService.addValueByKey(SESSION, token)
    storageService.addValueByKey(USER_EMAIL, userValues.email)
    history('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({payload: {history}}) {
  try {
    yield call([AuthService, AuthService.logout])
    yield put(logoutUserSuccess())
    storageService.clear()
    history('/login')
  } catch (error) {
    storageService.clear()
    history('/login')
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
