import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Table, Button, Spinner} from 'reactstrap'

import Pagination from 'components/Common/Pagination'
import PostPreview from 'components/Common/PostPreview'
import TableNoDataPlaceholder from 'components/TableComponents/TableNoDataPlaceholder'
import {POST_TYPE} from 'constants/post'
import dateByFormat from 'helpers/dateByFormat'

const ProfilePosts = ({
  t,
  loading,
  posts,
  totalPages,
  activePage,
  setActivePage,
  onPostView,
  onPostDownload,
  onPostDeleteClick,
}) => {
  if (loading) {
    return (
      <div>
        <h4>{t('All posts')}</h4>
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      </div>
    )
  }

  if (posts.length === 0) {
    return (
      <div>
        <h4>{t('All posts')}</h4>
        <TableNoDataPlaceholder classnames="profilePosts-empty" text={t('No posts found')} />
      </div>
    )
  }

  return (
    <div>
      <h4>{t('All posts')}</h4>
      <div className="overflow-auto">
        <Table>
          <thead className="profilePosts-thead">
            <tr className="text-nowrap">
              <th>{t('Post preview')}</th>
              <th>{t('Post title')}</th>
              <th>{t('Folder')}</th>
              <th>{t('Date of creation')}</th>
              <th>
                <div className="profilePosts-action-btns-box">
                  <div className="profilePosts-action-btns"> {t('Action')}</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => (
              <tr key={post.id}>
                <td className="centered-td">
                  <PostPreview type={post.type} src={post?.image?.compactPath ?? post?.video?.compactPath} />
                </td>
                <td className="centered-td">
                  <div lang="en" className="profilePosts-td-max-width min-width-250">
                    {post?.title ?? '-'}
                  </div>
                </td>
                <td className="centered-td">
                  <div lang="en" className="profilePosts-td-max-width">
                    {post?.breadcrumbs}
                  </div>
                </td>
                <td className="centered-td">{post?.createdAt ? dateByFormat(post?.createdAt) : ''}</td>
                <td className="centered-td">
                  <div className="profilePosts-action-btns-box">
                    <div className="profilePosts-action-btns">
                      {post.type === POST_TYPE.Text ? (
                        <Button
                          className="profilePosts-action-view"
                          color="primary"
                          onClick={onPostView.bind(null, post.id)}
                        >
                          {t('View')}
                        </Button>
                      ) : (
                        <Button
                          className="profilePosts-action-view"
                          color="primary"
                          onClick={onPostDownload.bind(null, post.id)}
                        >
                          {t('Download')}
                        </Button>
                      )}
                      <Button color="danger" onClick={onPostDeleteClick.bind(null, post.id)}>
                        {t('Delete')}
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="profilePosts-pagination">
        <Pagination totalPages={totalPages} activePage={activePage} setActivePage={setActivePage} />
      </div>
    </div>
  )
}

ProfilePosts.propTypes = {
  loading: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      image: PropTypes.shape({}),
      video: PropTypes.shape({}),
      createdAt: PropTypes.string,
      album: PropTypes.string,
    }),
  ),
  totalPages: PropTypes.number,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
  onPostView: PropTypes.func,
  onPostDeleteClick: PropTypes.func,
  onPostDownload: PropTypes.func,
}
export default withTranslation()(ProfilePosts)
