import {useState, useEffect, useCallback} from 'react'

import getApiError from 'helpers/getApiError'

import PostService from '../PostService'

export const useGetPostId = (postId) => {
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchPost = useCallback(async () => {
    setLoading(true)
    return PostService.getPostById(postId)
      .then(({data}) => setPost(data))
      .catch((error) => setError(getApiError(error)))
      .finally(() => setLoading(false))
  }, [postId])

  useEffect(() => {
    if (!postId) return
    fetchPost()
  }, [postId])

  return {post, error, loading, fetchPost}
}
