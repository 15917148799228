import React from 'react'

const TableSearch = ({onChange, filters, initialValue = ''}) => {
  return (
    <div className="search-box me-2 d-inline-block pb-2 pb-sm-0">
      <div className="position-relative">
        <input
          onChange={(e) => onChange(e, filters)}
          className="search-bar"
          placeholder="Search"
          defaultValue={initialValue}
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  )
}

export default TableSearch
